import { Controller } from "@hotwired/stimulus"

export default class WarningController extends Controller {
  static targets = ["message", "warner"]
  static classes = ["show", "hide"]
  static values = {
    europeOnly: Boolean,
    europe: Boolean,
    notForEurope: Boolean,
    invert: Boolean, // show message when NOT checked
    skipPageLoad: Boolean,
    animationSpeed: String,
    simple: Boolean // no animations, no checking for 'checked' warner
  }

  hideStyle() {
    if (this.hasHideClass)
      return `animate__${this.hideClass}`

    return 'animate__fadeOut'
  }

  showStyle() {
    if (this.hasShowClass)
      return `animate__${this.showClass}`

    return 'animate__fadeIn'
  }

  connect() {
    if (!this.hasMessageTarget)
      return

    if (!this.simpleValue) {
      let speed = this.animationSpeedValue || 'faster'
      this.messageTarget.classList.add('animate__animated', `animate__${speed}`)
      this.messageTarget.addEventListener('animationend', () => {
        if (this.messageTarget.classList.contains(this.hideStyle())) {
          this.messageTarget.classList.add('d-none')
        } else { // showing the element
          this.messageTarget.classList.remove('d-none')
        }
      })
    }

    if (this.skipPageLoadValue)
      return

    this.showMessage()
  }

  showMessage() {
    if (!this.hasWarnerTarget || !this.hasMessageTarget)
      return
    if (this.europeOnlyValue && !this.europeValue)
      return
    if (this.notForEuropeValue && this.europeValue)
      return

    let needToShow
    let selector = this.element.querySelectorAll('input[data-warning-target="warner"]:checked')
    if (this.invertValue) {
      needToShow = selector.length < 1
    } else {
      needToShow = selector.length
    }

    if (needToShow) {
      this.messageTarget.classList.remove(this.hideStyle(), 'd-none')
      this.messageTarget.classList.add(this.showStyle())
    } else {
      this.messageTarget.classList.add(this.hideStyle())
    }
  }

  showMessageSimple() {
    this.messageTarget.classList.remove('d-none')
  }

  hideMessage(event) {
    if (event.target.checked)
      this.messageTarget.classList.add(this.hideStyle())
  }
}
