import { Controller } from "@hotwired/stimulus"

export default class ClassController extends Controller {
  connect() {
    this.element.dataset.action += " click->class#toggleClass"
  }

  toggleClass(event) {
    // event.preventDefault()
    event.target.classList.toggle(event.params.class)
  }
}
