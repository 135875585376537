import { Controller } from "@hotwired/stimulus"

export default class CheckboxController extends Controller {
  static targets = ["checkbox", "template", "same", "controlled", "checkAll", "checkAllChildren",
                    "reconciler", "reconcilee", "forcerOfCheck"]

  static values = {
    forceOnLoad: { type: Boolean, default: true },
    shiftSelect: { type: Boolean, default: false }
  }

  connect() {
    if (this.forceOnLoadValue)
      if (this.hasForcerOfCheckTarget)
        this.forceCheck()
  }

  checkboxTargetConnected(element) {
    if (!this.shiftSelectValue)
      return

    let checkbox = element.querySelector("input[type=checkbox]")
    if (checkbox.dataset.action) {
      checkbox.dataset.action += " click->checkbox#check"
    } else {
      checkbox.dataset.action = "click->checkbox#check"
    }
  }

  check(event) {
    let checkboxes = Array.from(this.element.querySelectorAll("input[type=checkbox]"))
    let checkbox = event.target

    if (this.lastChecked == null) {
      this.lastChecked = checkbox
      return
    }

    if (event.shiftKey) {
      const start = checkboxes.indexOf(checkbox)
      const end = checkboxes.indexOf(this.lastChecked)
      checkboxes.slice(Math.min(start, end), Math.max(start, end) + 1)
                .forEach(checkbox => checkbox.checked = this.lastChecked.checked)
    }

    this.lastChecked = checkbox
  }

  toggleVisibilityByNodes(event) {
    let nodes = document.querySelectorAll(event.params.visibilityNodes)
    nodes.forEach(node => {
      if (this.checkboxTarget.checked) {
        node.classList.remove("d-none")
      } else {
        node.classList.add("d-none")
      }
    })
  }

  forceCheck() {
    this.forcerOfCheckTargets.forEach(forcerOfCheck => {
      document.querySelectorAll(forcerOfCheck.dataset.checkboxToForceParam).forEach(forcedControl => {
        if (forcerOfCheck.checked && !forcedControl.disabled) {
          forcedControl.checked = forcerOfCheck.checked
        }
      })
    })
  }

  preventUncheck(event) {
    event.target.checked = true
  }

  reconcileGroup() {
    this.reconcileeTarget.disabled = !this.reconcilerTarget.checked
  }

  controlIt() {
    this.controlledTargets.forEach(element => {
      element.disabled = !this.checkboxTarget.checked
    })
  }

  toggleCheck(event) {
    let inverteds = document.querySelectorAll(event.params.inverteds)
    let invertedIds = Array.from(inverteds).map(itm => itm.id)
    this.controlledTargets.forEach(element => {
      element.checked = !invertedIds.includes(element.id)
    })
  }

  toggleAll(e) {
    let checkbox = e.target
    let valToUse

    if (checkbox.checked) {
      valToUse = this.templateTarget.value
    } else {
      valToUse = '0'
    }
    this.sameTargets.forEach(fld => fld.value = valToUse)
  }

  checkAllBoxes() {
    let checked = this.checkAllTarget.checked
    this.checkAllChildrenTargets.forEach(cb => cb.checked = checked)
  }

  uncheckCheckbox(event) {
    let checkbox = document.querySelector(`input[value="${event.params.id}"]`)
    checkbox.checked = false
  }

  uncheckNodes() {
    this.checkboxTargets.forEach(element => {
      document.querySelectorAll(element.dataset.checkUncheckSelectorParam).forEach(uncheckee => {
        if (element.checked)
          uncheckee.checked = false
      })
    })
  }
}
