import { Controller } from "@hotwired/stimulus"

export default class VortexesController extends Controller {
  static targets = ["models"]

  modelChange() {
    console.log(this.selectedModel())
  }

  selectedModel() {
    return this.modelsTarget.querySelector("input[type=radio]:checked")
  }
}
