import { Controller } from "@hotwired/stimulus"

export default class TogglesController extends Controller {
  static targets = ["toggler", "togglee"]
  static values = { toggleVisibility: Boolean,
    invert: Boolean,
    skipOnLoad: Boolean }

  initialize() {
    if (this.toggleVisibilityValue) {
      this.toggleeTargets.forEach((togglee) => {
        togglee.classList.add('animate__animated')
        togglee.addEventListener('animationend', () => {
          if (togglee.classList.contains('animate__fadeOut')) {
            togglee.classList.add('d-none')
          } else { // showing the element
            togglee.classList.remove('d-none')
          }
          this.dispatch("finished")
        })
      })
    }

    if (!this.skipOnLoadValue) {
      this.toggle()

      if (this.element.querySelectorAll("[data-action*=disableForMe]:checked").length > 0)
        this.disableForMe()
    }
  }

  toggle() {
    if (this.toggleVisibilityValue) {
      this.toggleVisibility()
      return
    }

    this.toggleUsability()
  }

  toggleSimple() {
    let needToShow = this.toggleeTargets[0].classList.contains("animate__fadeOut")

    this.toggleeTargets.forEach((togglee) => {
      if (needToShow) {
        togglee.classList.remove('animate__fadeOut', 'd-none')
        togglee.classList.add('animate__fadeIn')
      } else {
        togglee.classList.add("animate__fadeOut")
      }
    })
  }

  radioResolveToggle() {
    this.toggle()
  }

  toggleUsability() {
    let anyChecked = false
    this.togglerTargets.forEach((toggler) => {
      if (toggler.checked)
        anyChecked = true
    })

    this.toggleeTargets.forEach((togglee) => {
      if (anyChecked) {
        togglee.disabled = false;
      } else {
        togglee.disabled = true;
        togglee.checked = false;
      }
    })
  }

  disableForMe() {
    this.toggleeTargets.forEach((togglee) => {
      togglee.disabled = true
      togglee.checked = false
    })
  }

  toggleVisibility() {
    let needToShow
    this.togglerTargets.forEach((toggler) => {
      if (toggler.checked)
        needToShow = true
    })
    if (this.invertValue)
      needToShow = !needToShow

    this.toggleeTargets.forEach((togglee) => {
      if (needToShow) {
        togglee.classList.remove('animate__fadeOut', 'd-none')
        togglee.classList.add('animate__fadeIn')
      } else {
        togglee.classList.add('animate__fadeOut')
      }
      return
    })
  }
}
