let vec = function() {
  return document.querySelector('[data-ec-vec=true]')
}
let noVec = function() {
  return document.querySelector('[data-ec-vec=false]')
}

let noCleaningDevice = function() { return document.querySelector('[data-ec-cleaning-device=false]') }
let cleaningDevice = function() { return document.querySelector('[data-ec-cleaning-device=true]') }
let noMonitoringSelected = function() {
  return document.querySelector('[data-ec-monitoring] [data-not-required=true]:checked');
}
let sixtyHertz = function() { return document.querySelector('[data-ec-sixty=true]') }

let reconcileThrottle = function() {
  // no throttle allowed if vec
  let throttle = document.querySelector('[data-ec-throttle] input[type=checkbox]')
  if (vec()) {
    throttle.checked = false;
    throttle.disabled = true;
    return true;
  }

  // regular fan
  if (document.querySelector('[data-ec-cleaning-device="true"]')) {
    throttle.checked = true;
    throttle.disabled = true;
  }
}

let reconcileEcMonitoring = function() {
  let monitoring = document.querySelector('[data-ec-monitoring]')
  let vecControls = monitoring.querySelectorAll('[data-monitor=true], [data-not-required=true]')
  let fanControls = monitoring.querySelectorAll('[data-regulator=true]')
  if (vec()) {
    vecControls.forEach((el) => {
      el.disabled = true;
      el.checked = false;
    })
    return true;
  }

  if (noVec()) {
    fanControls.forEach((el) => {
      el.disabled = true;
      el.checked = false;
    })
  }
}

// no motor protection switch with vec, cleaning device
// or any type of monitoring/regulation
let reconcileMps = function() {
  let mps = document.querySelector('[data-mps=true]')
  if (vec() || cleaningDevice() || !noMonitoringSelected() || sixtyHertz()) {
    mps.disabled = true;
    mps.checked = false;
    return true;
  }

  if (noVec() && noCleaningDevice() && noMonitoringSelected()) {
    mps.disabled = false;
  }
}

let reconcileDirectStarter = function() {
  let directStarter = document.querySelector('[data-starter=true]')
  let d3Checked = document.querySelector('[data-d3=true]:checked')
  if (d3Checked || sixtyHertz()) {
    directStarter.checked = false;
    directStarter.disabled = true;
  } else {
    directStarter.disabled = false;
  }
}

let reconcileController = function() {
  let controller = document.querySelector('[data-ec-wiring] [data-controller="true"]');
  let fiftyHertz = document.querySelector('[data-ec-fifty="true"]');
  let d3 = document.querySelector('[data-ec-monitoring] [data-d3="true"]');

  if (fiftyHertz && !d3.checked) {
    controller.disabled = false;
  } else {
    controller.disabled = true;
    controller.checked = false;
  }
}

document.addEventListener('turbo:load', function() {
  let cleaningMountedBack = document.querySelector('[data-ec-cleaning-back]')
  if (cleaningMountedBack) {
    let electricSide = document.querySelector('[data-ec-electric-side]')
    if (cleaningMountedBack.dataset.ecCleaningBack == 'true') {
      electricSide.classList.remove('collapse')
    } else {
      electricSide.classList.add('collapse')
    }
  }

  if (document.querySelector('[data-ec-throttle]'))
    reconcileThrottle()

  if (document.querySelector('[data-ec-monitoring]')){
    reconcileEcMonitoring();
    let monitoringOptions = document.querySelectorAll('[data-ec-monitoring] input[type=radio]');
    monitoringOptions && monitoringOptions.forEach((rdio) => {
      rdio.addEventListener('change', (event) => {
        reconcileDirectStarter();
        reconcileMps();
        reconcileController();
      })
    })
  }

  if (document.querySelector('[data-ec-wiring]')) {
    reconcileMps();
    reconcileDirectStarter();
    reconcileController();
  }
})
