import { Controller } from "@hotwired/stimulus"

// define a group of radio controls inside a usabilityGroup target
export default class RadioController extends Controller {
  static targets = ["note", "togglee", "usabilityGroup", "ensuredGroup", "ensuredNode"]
  static outlets = ["usability"]
  static values = {
    ensureChecked: { type: Boolean, default: false },
    nodes: { type: String, default: "" },
    ensuredNodes: { type: String, default: "" },
    toggleOnConnect: { type: Boolean, default: true },
    debug: { type: Boolean, default: false }
  }

  connect() {
    if (this.hasUsabilityGroupTarget) {
      this.usabilityGroupTarget.querySelectorAll("input[type=radio]").forEach(radio => {
        radio.dataset.action += " radio#toggleUsability"
      })

      if (this.toggleOnConnectValue) {
        this.toggleUsability()
      } else {
        if (this.debugValue) console.log("not toggle on start")
      }
    }
  }

  showOnlyOneNote(event) {
    this.noteTargets.forEach(note => note.classList.add("d-none"))
    let container = event.target.closest(".row")
    if (container)
      container.querySelectorAll("[data-radio-target=note]").forEach(note => note.classList.remove("d-none"))
  }

  toggleUsability(event) {
    let selectedControl = this.usabilityGroupTarget.querySelector("input[type=radio]:checked")

    let shouldDisable
    if (event === undefined) {
      if (this.debugValue) console.log("event is undefined")
      shouldDisable = selectedControl && selectedControl.dataset.radioDisablesParam == "true"
    } else {
      if (this.debugValue) console.log("event is defined")
      if (this.debugValue) console.log("event.params:")
      if (this.debugValue) console.log(event.params)
      shouldDisable = selectedControl && event.params.disables
    }

    if (this.debugValue) {
      if (event)
        console.log(`event.target: ${event.target.id}`)
      console.log(`selectedControl: ${selectedControl.id}`)
      console.log(`shouldDisable: ${shouldDisable}`)
      console.log("-----")
    }

    let targets
    if (this.nodesValue === "") {
      targets = this.toggleeTargets
    } else {
      targets = document.querySelectorAll(this.nodesValue)
    }

    targets.forEach(togglee => {
      if (shouldDisable) {
        if (this.debugValue) console.log(`disabling: ${togglee.id}`)
        togglee.disabled = true
        togglee.checked = false
        this.usabilityOutlets.forEach(outlet => outlet.toggleNodes())
      } else {
        togglee.disabled = false
      }
    })
    // if (this.debugValue) alert("targets are toggled")

    if (this.ensureCheckedValue && shouldDisable) {
      let groupToCheck
      let noEnsuredChecked
      if (this.ensuredNodesValue === "")  {
        groupToCheck = this.ensuredGroupTarget
      } else {
        groupToCheck = document.querySelector(this.ensuredNodesValue)
      }

      if (this.debugValue) {
        console.log("groupToCheck: ")
        console.log(groupToCheck)
      }

      noEnsuredChecked = groupToCheck.querySelectorAll("input[type=radio]:checked").length < 1

      if (noEnsuredChecked)
        groupToCheck.querySelector("input:not([disabled])").checked = true
    }

    if (this.debugValue) {
      console.log(this.element)
      console.log('toggleUsability is done')
      alert("toggleUsability end")
    }
  }
}
