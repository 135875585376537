import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['noStarter', 'styledol', 'stylevsd', 'stylestardelta', 'msstyle',
    'relay', 'noFav', 'favCommissioning', 'favPrice', 'favCost', 'xvent', 'burstDetector']

  connect() {
    this.syncStarterOptions()
    if (!this.noStarterTarget.checked) this.syncStarterModel()
    this.changeStarter()
    this.syncFav()
  }

  syncStarterOptions() {
    let inputs = document.querySelectorAll('.motor-starter-options input:not([type="number"])')
    let numbers = document.querySelectorAll('.motor-starter-options input[type="number"]')

    if (this.noStarterTarget.checked) {
      inputs.forEach((nput) => {
        nput.disabled = true
        nput.checked = false
      })
      numbers.forEach(nput => nput.disabled = true)
    } else {
      inputs.forEach(nput => nput.disabled = false)
      this.changeStarter()
    }
  }

  syncStarterModel() {
    if (this.noStarterTarget.checked) return
    let currentModel = document.querySelector('.motor-starter-models input:checked')

    this.styledolTarget.disabled = currentModel.dataset.dol != "true"
    this.stylevsdTarget.disabled = currentModel.dataset.vsd != "true"
    this.stylestardeltaTarget.disabled = currentModel.dataset.stardelta != "true"

    this.msstyleTargets.forEach(function(cb) {
      if (cb.disabled) cb.checked = false
    })
  }

  changeStarter() {
    if (this.stylevsdTarget.checked) {
      this.relayTarget.checked = false
      this.relayTarget.disabled = true
    } else {
      if (!this.noStarterTarget.checked) {
        this.relayTarget.disabled = false
      }
    }
  }

  syncFav() {
    if (!this.noFavTarget.checked) {
      this.favCommissioningTarget.disabled = false
    } else {
      this.favCommissioningTarget.disabled = true
      this.favCommissioningTarget.checked = false
      if (this.hasFavCostTarget)
        this.favCostTarget.disabled = true
      this.favPriceTarget.disabled = true
    }
  }

  xventToggle(e) {
    if (e.target.checked) {
      this.burstDetectorTarget.checked = true
    }
  }
}
