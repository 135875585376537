import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    displayClass: { type: String, default: "d-none" }
  }

  toggleTreeBranch(event) {
    event.preventDefault()
    let levelSelector = `[data-display-tree-target="branch${event.params.level}"]`
    event.target.closest("[data-display-tree-container]").querySelectorAll(levelSelector).forEach((branch) => {
      branch.classList.toggle(this.displayClassValue)
    })
  }
}
