import { Controller } from "@hotwired/stimulus"

export default class DefaultController extends Controller {
  static targets = ["defaultee", "defaulter"]

  static values = {
    number: Number
  }

  // this action has to be set first, before any toggles
  setDefault() {
    if (this.defaulterTarget.checked && this.defaulteeTarget.disabled)
      this.defaulteeTarget.value = this.numberValue
  }
}
