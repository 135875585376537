import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["filter"]

  connect() {
    let fldHTML = "<div class='col-md-6 col-lg-3 mb-3' id='filterField'>"
      + "<input type='search' placeholder='Search' class='form-control form-control-sm' "
      + "data-filter-target='filter' data-action='filter#filter'></div>"
    this.element.insertAdjacentHTML("afterbegin", fldHTML)
  }

  filter() {
    let tbl = this.element
    let filter = this.filterTarget.value.toUpperCase()
    tbl.querySelectorAll(".row").forEach(row => row.classList.add("d-none"))
    if (filter.trim() == "") {
      tbl.querySelectorAll(".row").forEach(row => row.classList.remove("d-none"))
      return
    }

    let matchingRows = []
    tbl.querySelectorAll(".row").forEach(row => {
      if (row.innerText.toUpperCase().includes(filter))
        matchingRows.push(row)
    })

    matchingRows.forEach(row => row.classList.remove("d-none"))
  }
}
