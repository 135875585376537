import { Controller } from "@hotwired/stimulus"

export default class BlowerController extends Controller {
  static targets = ["item", "groundMount", "model", "models", "defaultControl",
                    "weatherHood", "motorStarter", "sidePanel", "smf", "deductTransition",
                    "amcac", "standardConstruction"]

  static values = {
    acf: { type: Boolean, default: false },
    highStatic: { type: Boolean, default: false },
    specialFan: { type: Boolean, default: false }
  }

  connect() {
    this.modelChange()
  }

  modelChange() {
    if (this.modelsTarget.querySelectorAll('input:checked').length) {
      this.fanSelected()
    } else {
      this.fanNotSelected()
    }
  }

  fanNotSelected() {
    this.itemTargets.forEach((cntrl) => {
      cntrl.disabled = true
      if (cntrl.checked)
        cntrl.checked = false
    })

    this.defaultControlTargets.forEach((cntrl) => {
      cntrl.checked = true
    })
  }

  chosenFanModel() {
    return this.modelsTarget.querySelector("input:checked")
  }

  fanSelected() {
    document.querySelector("fieldset.integrated_controller").classList.remove("collapse")

    this.itemTargets.forEach((item) => {
      if (item.dataset.togglesTarget != "togglee")
        item.disabled = false
    })

    this.reconcileWeatherHood()
    if (this.acfValue)
      this.reconcileSmfs()
    this.reconcileStarters()
    // this.reconcileItcs() // FSF only??
    if (this.highStaticValue)
      this.reconcileAmcaC()
    this.reconcileSidePanels()
  }

  reconcileSidePanels() {
    const sidePanelCapable = this.chosenFanModel().dataset.sidePanels == "true"

    this.sidePanelTargets.forEach((sidePanel) => {
      sidePanel.disabled = !sidePanelCapable
      if (!sidePanelCapable)
        sidePanel.checked = false
    })
  }

  reconcileAmcaC() {
    const amcacCapable = this.chosenFanModel().dataset.amcac == "true"

    this.amcacTarget.disabled = !amcacCapable
    if (!amcacCapable && this.amcacTarget.checked) {
      this.amcacTarget.checked = false
      this.standardConstructionTarget.checked = true
    }
  }

  reconcileStarters() {
    const motorStarterCapable = this.chosenFanModel().dataset.motorStarter == "true"

    if (!this.hasMotorStarterTarget)
      return

    this.motorStarterTarget.disabled = !motorStarterCapable

    if (!motorStarterCapable)
      this.motorStarterTarget.checked = false
  }

  reconcileSmfs() {
    const smfCapable = this.chosenFanModel().dataset.smfs == "true"
    this.smfTargets.forEach((smf) => {
      smf.disabled =!smfCapable

      if (!smfCapable)
        smf.checked = false
    })
  }

  reconcileWeatherHood() {
    if (!this.hasGroundMountTarget)
      return

    if (this.groundMountTarget.checked) {
      this.weatherHoodTarget.disabled = true
      this.weatherHoodTarget.checked = false
    } else {
      this.weatherHoodTarget.disabled = false
    }
  }
}
