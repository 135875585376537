import { Controller } from "@hotwired/stimulus"

export default class ValidateController extends Controller {
  static values = {
    viaWizardLink: { type: Boolean, default: false }
  }

  validate(event) {
    let form = this.viaWizardLinkValue ? this.wizardForm() : this.element
    if (form.checkValidity() && this.checkCustomValidity(event)) {
      this.dispatch("finishedOk")
    } else {
      event.preventDefault()
      event.stopPropagation()
      form.classList.add("was-validated")

      if (form.querySelectorAll("input:invalid").length) {
        let nodeTop = form.querySelector("input:invalid").getBoundingClientRect().top - 250
        window.scrollTo(0, nodeTop)
      }
    }
  }

  wizardForm() {
    return document.querySelector(".yield[data-inside-project] form")
  }

  checkCustomValidity(event) {
    let form = this.element
    if (!form.dataset.customValidator)
      return true

    const otherController = this.application.getControllerForElementAndIdentifier(form, form.dataset.customValidator)
    return otherController.checkValid(event)
  }
}
