import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class WizardController extends Controller {
  static targets = ["form", "navLink", "totalPrice", "collectorPrice", "timedControl"]
  static values = {
    autoSubmit: { type: Boolean, default: true },
    collectorId: String,
    validityErrors: String,
    form: Object,
    skipCalculate: { type: Boolean, default: false },
    skipScroll: { type: Boolean, default: false },
    autoValidate: { type: Boolean, default: true },
    debug: { type: Boolean, default: false }
  }

  timedControlTargetConnected(target) {
    if (target.dataset.action) {
      target.dataset.action += " wizard#submitFormByTimedControl"
    } else {
      target.dataset.action = "wizard#submitFormByTimedControl"
    }
  }

  setup() {
    if (this.debugValue)
      console.log(`wizard setup(): ${document.baseURI}`)
    if (document.querySelectorAll("form[data-modern=true]").length < 1)
      return

    if (!this.autoSubmitValue)
      return

    // setup menu links to handle form validation on click
    document.querySelectorAll("[data-qow-nav-links] a").forEach(link => {
      link.addEventListener("click", (event) => this.validate(event))
    })

    let form = this.wizardForm()
    form.addEventListener("submit", (event) => {
      this.validate(event)
    })

    // no autosubmit for forms creating a new model
    if (form.baseURI.match("/new"))
      return

    // setup controls for submit on change
    let regularSelector = "input:not([type=text]):not([type=email]):not([type=number]):not(.timed-submit), select"

    form.querySelectorAll(regularSelector).forEach(control => {
      if (control.type == "search") {
        return
      }

      if (control.dataset.action === undefined) {
        control.dataset.action = "wizard#submitFormByControl"
      } else {
        control.dataset.action += " wizard#submitFormByControl"
      }

    })

    let timedSelector = "input[type=text], input[type=email], input[type=number], textarea, .timed-submit"
    form.querySelectorAll(timedSelector).forEach(control => {
      if (control.dataset.action) {
        control.dataset.action += " wizard#submitFormByTimedControl"
      } else {
        control.dataset.action = "wizard#submitFormByTimedControl"
      }
    })
  }

  submitFormByTimedControl(event) {
    clearTimeout(this.typingTimeout)

    this.typingTimeout = setTimeout(() => {
      this.submitFormByControl(event)
    }, 800)
  }

  validate(event) {
    if (!this.autoValidateValue && !event.type.match(/submit|click/))
      return true

    let form = this.wizardForm()
    if (form.checkValidity() && this.checkCustomValidity(event)) {
      this.dispatch("finishedOk")
      return true
    } else {
      console.log("not valid")
      event.preventDefault()
      event.stopPropagation()

      form.classList.add("was-validated")

      if (form.querySelectorAll("input:invalid, select:invalid").length < 1)
        return

      if (this.skipScrollValue)
        return false

      if (event.type.match(/submit|click/)) {
        let invalidControl = form.querySelector("input:invalid, select:invalid")
        let rect = invalidControl.getBoundingClientRect()
        window.scrollTo(0, rect.top + window.scrollY - 250)
        return false
      }
    }
  }

  checkCustomValidity(event) {
    let form = this.wizardForm()
    if (form.dataset.customValidator) {
      const otherController = this.application.getControllerForElementAndIdentifier(form, form.dataset.customValidator)
      return otherController.checkValid(event)
    } else {
      return true
    }
  }

  async submitFormByControl(event) {
    if (!this.validate(event))
      return

    let form = this.wizardForm()
    let url = event.target.dataset.skipCalculate ? form.action + "?skip_calculate=true" : form.action
    const formData = new FormData(form)
    const request = new FetchRequest(form.method, url, { body: formData })
    const response = await request.perform()
    if (!response.ok) {
      console.log(`Problem: ${response}`)
    } else {
      event.target.closest("form").classList.remove("was-validated")

      if (this.skipCalculateValue || response.response.url.includes("skip_calculate"))
        return

      let txt = await response.text
      if (txt == "" && response.ok)
        return

      let body = JSON.parse(txt)
      document.querySelector("[data-total-price]").textContent = body.project_total
      document.querySelector("[data-collector-price]").textContent = body.collector_total
    }

    if (event.target.dataset.affectsHeight)
      this.retrieveHeight()
  }

  async retrieveCollectorTotals() {
    const request = new FetchRequest("get", `/collectors/${this.collectorIdValue}/retrieve_totals`)
    const response = await request.perform()
    if (!response.ok) {
      console.log(`Problem w/ retreiveTotals: ${response}`)
    } else {
      let body = JSON.parse(await response.text)
      document.querySelector("[data-total-price]").textContent = body.project_total
      document.querySelector("[data-collector-price]").textContent = body.collector_total
    }
  }

  async retrieveHeight() {
    if (!document.querySelector("#estimated-height-val"))
      return

    const request = new FetchRequest("get", `/collectors/${this.collectorIdValue}/height`)
    const response = await request.perform()
    if (!response.ok) {
      console.log(`Problem w/ height: ${response}`)
    } else {
      let body = JSON.parse(await response.text)
      document.querySelector("#estimated-height-val").textContent = body
    }
  }

  wizardForm() {
    let allQowForms = document.querySelectorAll('div[data-qow="true"] form')

    if (allQowForms.length == 1)
      return allQowForms[0]

    let actualQowForm = document.querySelector('form[data-qow-specific-form]')
    if (actualQowForm) {
      return actualQowForm
    } else {
      return allQowForms[0]
    }
  }
}
