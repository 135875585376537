document.addEventListener('turbo:load', function(){
  document.querySelectorAll('body.emo_twos [data-usage] input[type=radio]').forEach((rdio) => {
    rdio.addEventListener('change', (event) => {
      let finalFilter = document.querySelector('[data-final-filter]')
      let isEmulsion = rdio.dataset.emulsion == 'true'
      let msgFinalFilter = document.querySelector('[data-message-final-filter]')

      if (isEmulsion) {
        finalFilter.disabled = false;
      } else {
        finalFilter.checked = true;
      }
      msgFinalFilter.classList.toggle('collapse', isEmulsion)
    })
  })

  let finalFilter = document.querySelector('#emo_two_final_filter')
  finalFilter && finalFilter.addEventListener('change', function(event) {
    let isEmulsion = document.querySelector('[data-usage] input:checked').dataset.emulsion == 'true'
    if (!event.target.checked && !isEmulsion) {
      event.target.checked = true;
    }
  })

  let checkedUsage = document.querySelector('body.emo_twos [data-usage] input:checked');
  if (checkedUsage) {
    checkedUsage.dispatchEvent(new Event('change'))
  }
})
