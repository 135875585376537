document.addEventListener('turbo:load', function(){
  if (!document.querySelectorAll('fieldset[data-controls="true"]').length) return

  let controllers = document.querySelectorAll('[data-controls="true"] input[type="radio"]')
  let vfd = document.querySelector('[data-vfd="true"]')
  let runtimeMeter = document.querySelector('[data-runtime="true"]')
  let controllerOptions = document.querySelectorAll('[data-controller-options="true"] input')

  controllers.forEach((controller) => controller.addEventListener('change', function() {
    if (this.dataset.controller !== 'true') {
      // no options allowed for motor starter or 'Not Required'
      controllerOptions.forEach(function(option) {
        option.disabled = true
        option.checked = false
      })
      return
    } else {
      controllerOptions.forEach((option) => {
        option.disabled = false
      })
    }

    // if advanced controller
    if (this.dataset.controlAdvanced == 'true') {
      // enable VFD
      vfd.disabled = false

      if (runtimeMeter) {
        runtimeMeter.disabled = true
        runtimeMeter.checked = false
      }
    } else {
      vfd.disabled = true
      vfd.checked = false

      // basic controller
      if (controller.dataset.controller == 'true' && runtimeMeter)
        runtimeMeter.disabled = false
    }
  }))

  document.querySelectorAll('[data-controls="true"] input:checked').forEach((controller) => {
    controller.dispatchEvent(new Event('change'))
  })

  // disable basic controller if pump
  // use DisablesOnLoadController
  let basicController = document.querySelector('[data-control-basic="true"]')
  if (document.querySelector('[data-basic-available="false"]')) {
    basicController.checked = false
    basicController.disabled = true
  }

  // disable motor starter if overfill protection needed
  if (!document.querySelectorAll('[data-overfill="true"]').length) return
  let motorStarter = document.querySelector('[data-starter="true"]')
  let overfill = document.querySelector('[data-overfill="true"]')
  overfill.addEventListener('change', function() {
    if (overfill.checked) {
      motorStarter.disabled = true
      motorStarter.checked = false
    } else {
      motorStarter.disabled = false
    }
  })
  overfill.dispatchEvent(new Event('change'))
})
