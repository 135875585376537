import { Controller } from "@hotwired/stimulus"

export default class StingerSelectionController extends Controller {
  static targets = ["warning"]

  static values = {
    airflow: String
  }

  setupStinger(event) {
    document.querySelector("#stinger_selection_diameter").value = event.params.diameter
    this.warningTargets.forEach(warning => warning.classList.add("d-none"))

    if (this.airflowValue > event.params.max)
      event.target.closest("tr").querySelector("[data-stinger-selection-target='warning']").classList.remove("d-none")
  }
}
