document.addEventListener('turbo:load', function(){
  // disable n/a quantum airflow/application combinations
  document.querySelectorAll(".quantum-airflows input").forEach(radio => {
    radio.addEventListener("click", () => {
      for (const dataItem in radio.dataset) {
        if (!dataItem.includes("qsApplication"))
          return

        document.querySelectorAll(`.${dataItem}`).forEach(input => {
          if (radio.dataset[dataItem] == "true") {
            input.disabled = false 
          } else {
            input.disabled = true
            input.checked = false
          }
        })
      }
    })
  })
})
