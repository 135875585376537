import { Controller } from "@hotwired/stimulus"

export default class ContactsController extends Controller {

  link(event) {
    event.preventDefault()
    let selectors = []
    let values = []
    for(let key in event.params) {
      selectors.push(`[data-contacts-receive="${key}"]`)
      values.push(event.params[key])
    }

    for(let key in selectors) {
      let cntrl = document.querySelector(selectors[key])
      if (cntrl)
        cntrl.value = values[key]
    }

    this.dispatch("linkFinished")
  }
}
