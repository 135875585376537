import { Controller } from "@hotwired/stimulus"

export default class EnablesNodesController extends Controller {
  static targets = ["enabler", "enablee"]
  static values = {
    numberChecked: { type: Number, default: 0 },
    debug: { type: Boolean, default: false },
    uncheck: { type: Boolean, default: false },
    nodes: { type: String, default: "" }
  }

  connect() {
    this.toggle()
  }

  numberCheckedValueChanged(_current, old) {
    if (old === undefined) // don't run on startup
      return

    let nodes = document.querySelectorAll(this.nodesValue)
    nodes.forEach(node => {
      node.disabled = this.numberCheckedValue == 0

      if (this.uncheckValue && node.disabled && node.checked)
        node.checked = false
    })
  }

  toggle() {
    let number = 0
    this.enablerTargets.forEach(enabler => {
      if (enabler.checked)
        number++
    })
    this.numberCheckedValue = number
  }
}
