import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    defaultTopPadding: { type: Number, default: 150 }
  }

  scrollTo(event) {
    event.preventDefault()

    let workingElement = event.params.to || event.detail.element
    if (event.params.to)
      workingElement = document.querySelector(workingElement)

    if (workingElement) { // params -> coming direct, detail -> from dispatch
      let topPadding = event.params.topPadding ? event.params.topPadding : this.defaultTopPaddingValue
      let nodeTop = workingElement.getBoundingClientRect().top + window.scrollY - topPadding
      window.scrollTo(0, nodeTop)
    }
  }

  scrollExtraForAnchor(event) {
    event.preventDefault()
    let anchor = document.querySelector(`#${event.params.id}`)
    anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    anchor.classList.add("highlight")
    setTimeout(() => {
      anchor.classList.remove("highlight")
    }, 1500)
  }
}
