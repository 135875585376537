import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class ZipCodesController extends Controller {
  static targets = ["query", "countries"]

  chooseCountry(event) {
    event.preventDefault()
    this.queryTarget.value = event.target.textContent
    this.countriesTarget.classList.add("d-none")
    let frm = this.element.querySelector("form")
    Rails.fire(frm, 'submit')
  }

  search(event) {
    this.queryTarget.value = ""
    let selector = ".search-result"
    this.element.querySelectorAll(selector).forEach(itm => itm.remove())
    let exceptions = this.element.querySelector(".exceptions")
    exceptions.insertAdjacentHTML("afterend", event.detail[2].response)
    this.dispatch("searchFinished", { detail: { selector: ".search-result" } })
  }
}
