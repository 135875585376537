import { Controller } from "@hotwired/stimulus"

export default class StandardFeatureController extends Controller {
  values = {
    userTranslationId: Number
  }

  displayForm(event) {
    let xhr = event.detail[2].response
    this.element.querySelectorAll("[data-custom-feature-form]").forEach(el => el.remove())
    event.target.insertAdjacentHTML("afterend", xhr)
  }

  create(event) {
    let element = event.target.closest("[data-controller=standard-feature]")
    element.insertAdjacentHTML("afterend", event.detail[2].response)
    element.remove()
  }

  edit(event) {
    let element = event.target.closest("[data-controller=standard-feature]")
    element.insertAdjacentHTML("beforeend", event.detail[2].response)
  }

  archive(event) {
    let element = event.target.closest("[data-controller=standard-feature]")
    element.insertAdjacentHTML("afterend", event.detail[2].response)
    element.remove()
    event.stopPropagation()
  }
}
