import { Controller } from "@hotwired/stimulus"

export default class ContainerClassController extends Controller {
  connect() {
  }

  toggleClass(event) {
    this.element.classList.toggle(event.params.class)
  }
}
