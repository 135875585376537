import { Controller } from "@hotwired/stimulus"

export default class GsFansController extends Controller {
  static targets = ["notRequired", "configureLink", "characteristics", "types", "messageNoPulseController",
                    "messageSolenoids", "noController", "pulseControllers", "rotationOpposite", "quantity"]
  static classes = ["hidden"]
  static values = {
    blowerType: String,
    controllerSelected: Boolean
  }

  connect() {
    this.reconcileFanType()
    this.reconcilePulseController()
  }

  reconcileFanType() {
    if (this.notRequiredTarget.checked) {
      this.configureLinkTarget.classList.add(this.hiddenClass)
      this.characteristicsTarget.classList.add(this.hiddenClass)
      return
    }

    this.configureLinkTarget.classList.remove(this.hiddenClass)
    this.characteristicsTarget.classList.remove(this.hiddenClass)

    let chosenType = this.typesTarget.querySelector("input:checked")
    if (chosenType.value == this.blowerTypeValue)
      this.reconcilePulseController()

    this.rotationOppositeTarget.disabled = parseInt(this.quantityTarget.value) != 2;
  }

  reconcilePulseController() {
    if (!this.controllerSelectedValue)
      return

    this.messageNoPulseControllerTarget.classList.remove(this.hiddenClass)
    this.messageSolenoidsTarget.classList.add(this.hiddenClass)
    this.noControllerTarget.checked = true
    this.pulseControllersTarget.querySelectorAll(".form-check").forEach(checkbox => checkbox.classList.add(this.hiddenClass))
  }
}
