import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'basic', 'yesBasic', 'noBasic', 'advanced', 'yesAdvanced',
                     'noAdvanced', 'integratedFan', 'topMount', 'exhaustHousing',
                     'controlNotRequired', 'fanOption', 'topMountOption',
                     'integratedOption', 'groundOnly' ];

  connect() {
    this.baseChange();
    this.topMountChange();
  }

  baseChange() {
    if (!this.hasTopMountTarget)
      return

    this.topMountTarget.disabled = this.integratedFanTarget.checked;

    if (this.integratedFanTarget.checked) {
      this.topMountTarget.checked = false;

      this.topMountOptionTargets.forEach((el) => {
        el.disabled = true;
        el.checked = false;
      });
    }
  }

  topMountChange() {
    if (!this.hasTopMountTarget)
      return

    if (this.topMountTarget.checked) {
      this.topMountOptionTargets.forEach((el) => el.disabled = false);
      if (this.exhaustHousingTarget.checked) {
        if (this.hasGroundOnlyTarget)
          this.groundOnlyTarget.checked = true;
      }
    } else {
      this.topMountOptionTargets.forEach((el) => el.disabled = true);
    }
  }

  controlChange() {
    if (this.basicTarget.checked) {
      this.noBasicTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.yesBasicTargets.forEach((element) => element.disabled = false);
    }

    if (document.querySelectorAll('[data-target="profi-fan.advanced"]:checked').length) {
      this.yesBasicTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.noBasicTargets.forEach((element) => element.disabled = false);
    }

    if (this.controlNotRequiredTarget.checked) {
      this.yesBasicTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.noBasicTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
    }
  }
}
