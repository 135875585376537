import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="page"
export default class extends Controller {
  connect() {
    console.log("connect page")
  }

  log(event) {
    // console.log("log start")
    // console.log(event)
    console.log(event.type)
    // console.log(event.detail)
    // console.log(event.params.msg)
    // if (event.detail.url)
    //   console.log(event.detail.url)
    // if (event.detail.action)
    //   console.log(event.detail.action)
    // console.log("----")
  }
}
