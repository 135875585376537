import { Controller } from "@hotwired/stimulus"

export default class DisablesController extends Controller {
  static targets = ["disabler", "disablee", "disablerByNodes"]
  static values = {
    debug: { type: Boolean, default: false }
  }

  connect() {
    this.disablerTargets.forEach(disabler => {
      this.disableeTargets.forEach(disablee => disablee.disabled = disabler.checked)
    })
    this.toggleNodes()

    if (this.hasDisablerByNodesTarget) {
      this.disablerByNodesTargets.forEach(element => {
        if (element.dataset.action == undefined) {
          element.dataset.action = "disables#disableNodes"
        } else {
          element.dataset.action += " disables#disableNodes"
        }
      })

      let checkedInput = this.element.querySelector("[data-disables-target*=disablerByNodes]:checked")
      if (checkedInput)
        this.disableNodes({ params: { nodes: checkedInput.dataset.disablesNodesParam } })

      if (this.debugValue)
        console.log("disables connect finished")
    }
  }

  toggle(event) {
    this.disableeTargets.forEach(disablee => { disablee.disabled = event.target.checked })
  }

  disable(event) {
    this.disableeTargets.forEach(disablee => {
      if (event.target.checked)
        disablee.disabled = true
    })
  }

  disableNodes(event) {
    this.disablerByNodesTargets.forEach(disabler => {
      if (disabler.checked) {
        document.querySelectorAll(event.params.nodes).forEach(disablee => {
          disablee.disabled = true
        })
      }
    })
  }

  toggleNodes() {
    document.querySelectorAll("[data-action*='disables#toggleNodes']").forEach(toggler => {
      let disablees = document.querySelectorAll(toggler.dataset.disablesNodesParam)
      disablees.forEach(disablee => { disablee.disabled = toggler.checked })
    })
  }
}
