import { Controller } from "@hotwired/stimulus"

export default class FinalizeController extends Controller {
  static targets = ["worksheets", "quoteCustomization", "terms", "weeks", "deliveredTo", "exWorks",
    "shippingLocation", "quoteSheetLinks", "europeQuoteRequirementsMessages",
    "europe", "disclaimerStandard", "disclaimerAtex", "bundleLinks", "outputDocuments", "customer",
    "receivedOrder", "probability", "statusDead", "estimatedClose"]

  static values = {
    europe: { type: Boolean, default: false },
    outputDocumentsClicked: { type: Boolean, default: false }
  }

  connect() {
    this.typingTimeout = null
    if (this.hasQuoteCustomizationTarget) {
      this.dispatch("elementsToToggle", { detail: { elementsToToggle: this.elementsToToggle() } })
      this.reconcileProbability()
      if (this.europeValue)
        this.reconcileQuoteEurope()
    }

    if (this.outputDocumentsClickedValue)
      this.outputDocumentsTarget.classList.remove("d-none")
  }

  elementsToToggle() {
    let elements = []
    if (document.cookie.includes("showPrices=true")) {
      document.querySelectorAll("[data-toggles-pricing]").forEach(element => {
        elements.push(element)
      })
    }

    if (document.cookie.includes("showCustomization=true"))
      elements.push(this.quoteCustomizationTarget)

    return elements
  }

  reconcileQuoteEurope() {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => {
      if (this.quoteAvailableForEurope()) {
        this.quoteSheetLinksTarget.classList.remove("d-none")
        this.europeQuoteRequirementsMessagesTarget.classList.add("d-none")
      } else {
        this.quoteSheetLinksTarget.classList.add("d-none")
        this.europeQuoteRequirementsMessagesTarget.classList.remove("d-none")
      }
    }, 500)
  }

  quoteAvailableForEurope() {
    if (this.termsTarget.value.trim() == "")
      return false

    if (this.weeksTarget.value.trim() == "")
      return false

    if (this.deliveredToTarget.checked && this.shippingLocationTarget.value.trim() == "")
      return false

    if (!this.exWorksTarget.checked && !this.deliveredToTarget.checked)
      return false

    if (!this.disclaimerStandardTarget.checked && !this.disclaimerAtexTarget.checked)
      return false

    return true
  }

  viewQuoteSheet() {
    let elements = [this.outputDocumentsTarget, this.bundleLinksTarget]
    this.dispatch("viewQuoteSheetClick", { detail: { elementsToToggle: elements } })
  }

  orderSheetClick(event) {
    let form = event.target.closest("form")
    this.customerTarget.querySelectorAll("input:not(.excepted)").forEach(input => input.required = true)
    if (!form.checkValidity()) {
      event.preventDefault()
      form.classList.add("was-validated")
    }
  }

  reconcileOrderNotReady() {
    let notReady = document.querySelector(".order-not-ready")

    if (notReady.querySelectorAll("li").length < 1) {
      notReady.classList.add("d-none")
      document.querySelector("#order_links").classList.remove("d-none")
    }
  }

  markReceivedOrder() {
    this.receivedOrderTarget.checked = true
  }

  reconcileProbability() {
    this.probabilityTargets.forEach(probability => {
      if (this.receivedOrderTarget.checked) {
        probability.checked = false
        probability.disabled = true
      } else {
        probability.disabled = false
      }
    })

    if (this.statusDeadTarget.checked)
      this.estimatedCloseTarget.value = ""
  }
}
