import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  highlight(event) {
    if (event.target.checked) {
      this.element.classList.add('highlight')
    } else {
      this.element.classList.remove('highlight')
    }
  }
}
