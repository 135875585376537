import { Controller } from "@hotwired/stimulus"

export default class MediaAreaController extends Controller {
  static targets = ["specialMedia", "specialMediaArea", "cartridges", "mediaAreaDisplay", "miningWarning",
                    "ratioDisplay", "modelId"]
  static values = {
    measurementSystem: { type: String, default: "imperial" },
    airflow: Number,
    mining: { type: Boolean, default: false }
  }

  updateMediaArea() {
    let selectedModel = this.modelIdTarget.querySelector("option:checked")
    let numberOfFilters = selectedModel.dataset.cartridges

    let filterArea = 0
    if (this.specialMediaTarget.checked) {
      filterArea = parseInt(this.specialMediaAreaTarget.value)
    } else {
      let filter = this.cartridgesTarget.querySelector("input[type='radio']:checked")
      filterArea = parseInt(filter.dataset.mediaArea)
    }

    let imperialMediaArea = numberOfFilters * filterArea
    let metricMediaArea = Math.round(imperialMediaArea * 0.0929)

    let metricAirflow = 0
    let imperialAirflow = 0
    if (this.measurementSystemValue == "metric") {
      metricAirflow = this.airflowValue
      imperialAirflow = this.airflowValue * 0.5886
      this.mediaAreaDisplayTarget.textContent = metricMediaArea
    } else {
      metricAirflow = this.airflowValue * 1.6990
      imperialAirflow = this.airflowValue
      this.mediaAreaDisplayTarget.textContent = imperialMediaArea
    }

    let metricMediaRatio = metricAirflow / metricMediaArea
    let imperialMediaRatio = imperialAirflow / imperialMediaArea

    if (imperialMediaRatio >= 2.0) {
      if (this.miningValue) {
        if (this.hasMiningWarningTarget)
          this.miningWarningTarget.classList.remove("d-none")
        this.ratioDisplayTarget.classList.add("text-danger")
      }
    } else {
      if (this.hasMiningWarningTarget)
        this.miningWarningTarget.classList.add("d-none")
      this.ratioDisplayTarget.classList.remove("text-danger")
    }

    metricMediaRatio = metricMediaRatio.toFixed(1)
    imperialMediaRatio = imperialMediaRatio.toFixed(2) + ":1"

    if (this.measurementSystemValue == "metric") {
      this.ratioDisplayTarget.textContent = metricMediaRatio + " m³/m²/h"

    } else {
      this.ratioDisplayTarget.textContent = imperialMediaRatio
    }
  }
}
