import { Controller } from "@hotwired/stimulus"

export default class UserTranslationController extends Controller {
  static values = {
    translatableType: String,
    createTargetSelector: String,
    option: { type: Boolean, default: false },
    productId: Number
  }

  create(event) {
    let translation = `[data-user-translation-${this.translatableTypeValue}-id="${event.params.translatableId}"]`
    document.querySelectorAll(translation).forEach(element => {
      element.remove()
    })
    let xhr = event.detail[2].response

    if (this.hasCreateTargetSelectorValue) {
      let already = document.querySelector(this.createTargetSelectorValue)
      if (already)
        already.insertAdjacentHTML("afterend", xhr)
    }
  }

  delete(event) {
    let xhr = event.detail[2].response
    if (this.optionValue) {
      document.querySelector(`[data-product-id="${this.productIdValue}"]`).insertAdjacentHTML("beforeend", xhr)
    } else {
      document.querySelector("[data-standard-features]").insertAdjacentHTML("beforeend", xhr)
    }
    this.element.remove()
  }
}
