import { Controller } from "@hotwired/stimulus"

export default class CheckDisableController extends Controller {
  static targets = ["disabler"]
  static values = {
    nodes: String,
    skipFireOnLoad: { type: Boolean, default: false },
    debug: String
  }

  disablerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "check-disable#disableNodes"
    } else {
      target.dataset.action += " check-disable#disableNodes"
    }
  }

  connect() {
    if (this.debugValue)
      console.log("connect check-disable")
    if (this.skipFireOnLoadValue)
      return

    setTimeout(() => {
      this.disablerTargets.forEach(disabler => {
        disabler.dispatchEvent(new Event("input"))
      })
    }, 333)
  }

  disableNodes(event) {
    if (this.debugValue)
      console.log(this.nodesValue)

    document.querySelectorAll(this.nodesValue).forEach(node => {
      if (event.target.checked) {
        if (this.debugValue)
          console.log("is checked")
        node.disabled = true
        node.checked = false
      }
    })
  }
}
