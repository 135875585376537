import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["datePicker"];
  static values = {
    numberOfMonths: { type: Number, default: 1 },
    autoOpen: { type: Boolean, default: false }
  }

  connect() {
    this.datePickerTargets.forEach(picker => {
      let opts = { allowInput: true, altInput: false, showMonths: this.numberOfMonthsValue }

      if (picker.dataset.datePickerStartInPast == "true")
        opts.onOpen = this.pastOpen

      if (picker.dataset.datePickerRange == "true")
        opts.mode = "range"

      let fp = flatpickr(picker, opts)

      if (this.autoOpenValue)
        fp.open()

    })
  }

  pastOpen(selectedDates, dateStr, flatpickrRef) {
    if (!dateStr)
      flatpickrRef.changeMonth(-1);
  }
}
