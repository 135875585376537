import { Controller } from "@hotwired/stimulus"

export default class ShowSpecificController extends Controller {
  static targets = ["shower"]
  static values = {
    anyChecked: { type: Boolean, default: false },
    nodes: { type: String, default: "" }
  }

  showerTargetConnected(target) {
    target.dataset.action += " show-specific#show"
  }

  connect() {
    this.show()
  }

  show() {
    if (this.anyCheckedValue) {
      let anyChecked = false
      this.showerTargets.forEach(shower => {
        if (shower.checked)
          anyChecked = true
      })

      if (anyChecked)
        document.querySelectorAll(this.nodesValue).forEach(element => element.classList.remove("d-none"))

      return
    }

    document.querySelectorAll(this.nodesValue).forEach(element => {
      if (this.showerTarget.checked) {
        element.classList.remove("d-none")
      }
    })
  }
}
