import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    animate: { type: Boolean, default: false },
    dismissClass: { type: String, default: "animate__fadeOut" },
    speedClass: { type: String, default: "animate__animated" }
  }

  initialize() {
    if (!this.animateValue)
      return

    this.element.classList.add("animate__animated", this.speedClassValue)
    this.element.addEventListener("animationend", () => {
      this.element.classList.remove()
      this.element.classList.add("d-none")
    })
  }

  dismiss(event) {
    event.preventDefault()
    this.remove()
    this.dispatch("finished")
  }

  dismissNode(event) {
    this.dismissBySelector(event)
  }

  dismissBySelector(event) {
    event.preventDefault()
    document.querySelectorAll(event.params.elementToDismiss).forEach(removee => {
      removee.remove()
    })

    this.dispatch("finished")
  }

  clearNodes(event) {
    event.preventDefault()
    document.querySelectorAll(event.params.nodes).forEach(node => node.innerHTML = "")
  }

  dismissAndMessage(event) {
    this.element.remove()
    let msg = `<div class="alert alert-info">${event.params.message}</div>`
    document.querySelector(".yield").insertAdjacentHTML('afterbegin', msg)
  }

  remove() {
    if (this.animateValue) {
      this.animatedRemove(this.elementToRemove())
      return
    }

    this.elementToRemove().remove()
  }

  animatedRemove(elementToRemove) {
    elementToRemove.classList.add(this.dismissClassValue)
  }

  elementToRemove() {
    return this.wrapper() || this.element
  }

  wrapper() {
    return this.element.closest("[data-crud-wrapper='true']") || false
  }
}
