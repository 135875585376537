import { Controller } from "@hotwired/stimulus"

export default class LinkCheckController extends Controller {
  static values = {
    nodes: String
  }

  checkNodes(event) {
    event.preventDefault()
    let elements = document.querySelectorAll(this.nodesValue)
    elements.forEach(element => {
      element.checked = true
      element.dispatchEvent(new Event("input"))
    })
  }
}
