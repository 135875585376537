import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'vec', 'electricMotorStarter', 'dpc', 'secondary',
                     'mql', 'oil', 'emulsion', 'throttle', 'cleaning' ];

  connect() {
    this.toggleVec();
    this.changeUsage();
  }

  toggleVec(e) {
    if (this.vecTarget.checked) {
      this.electricMotorStarterTarget.disabled = true;
      this.electricMotorStarterTarget.checked = false;
      this.throttleTarget.disabled = true;
      this.throttleTarget.checked = false;
      this.dpcTarget.disabled = true;
      this.dpcTarget.checked = true;
    } else {
      this.electricMotorStarterTarget.disabled = false;
      this.throttleTarget.disabled = false;
      this.dpcTarget.disabled = false;
    }
  }

  changeUsage(e) {
    if (this.oilTarget.checked || this.mqlTarget.checked) {
      this.secondaryTarget.checked = true;
      this.cleaningTarget.disabled = true;
      this.cleaningTarget.checked = false;
    } else { // emulsion clicked...
      this.cleaningTarget.disabled = false;
    }
  }

  toggleSecondary(e) {
    if (this.oilTarget.checked || this.mqlTarget.checked) {
      this.secondaryTarget.checked = true;
    }
  }
}
