import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["perBelow", "requiredComponent", "unusedList", "warning",
                    "builtIn", "custom", "list", "orientationsField", "customComponentsField"]
  static values = {
    inClass: { type: String, default: "animate__bounceIn" },
    remindClass: { type: String, default: "animate__headShake" }
  }

  connect() {
    this.listTargets.forEach((list) => {
      Sortable.create(list, {
        animation: 150,
        group: "collector",
        onAdd: this.componentAddedToList
      })
    })

    if (this.hasWarningTarget) {
      this.warningTarget.addEventListener('animationend', () => {
        this.warningTarget.classList.remove(this.inClassValue)
        this.warningTarget.classList.remove(this.remindClassValue)
      })
    }
  }

  verify(event) {
    if (this.hasMissingComponents()) {
      event.preventDefault()

      if (this.hasWarningTarget) {
        if (!this.warningTarget.classList.contains('d-none'))
          this.warningTarget.classList.add(this.remindClassValue)
        this.warningTarget.classList.add(this.inClassValue)
        this.warningTarget.classList.remove('d-none')
      }

    } else {
      this.gatherComponents()
      this.gatherCustomComponents()
      event.target.submit()
    }
  }

  gatherComponents() {
    let orientations = {}
    orientations.results = []
    this.builtInTargets.forEach((builtIn) => {
      let result = new Object()
      if (builtIn.dataset.added == "true") {
        result.type = builtIn.dataset.type
        result.time = builtIn.closest('[data-time]').dataset.time
        orientations.results.push(result)
      }
    })

    this.orientationsFieldTarget.value = JSON.stringify(orientations)
  }

  gatherCustomComponents() {
    let customs = []
    this.customTargets.forEach((customComponent) => {
      if (customComponent.dataset.added == "true") {
        let timeDiv = customComponent.closest('[data-time]')
        customs.push(`${customComponent.dataset.id}=${timeDiv.dataset.time}`)
      } else {
        customs.push(`${customComponent.dataset.id}=`)
      }
    })

    this.customComponentsFieldTarget.value = customs.join('|')
  }

  hasMissingComponents() {
    if (!this.hasPerBelowTarget)
      return false
    if (!this.perBelowTarget.checked)
      return false

    let returnValue = false
    this.requiredComponentTargets.forEach((requiredComponent) => {
      if (this.unusedListTarget.contains(requiredComponent)) {
        returnValue = true
      }
    })

    return returnValue
  }

  componentAddedToList(event) {
    document.querySelector("#collector_location_per_below").checked = true

    let newList = event.to
    if (newList.dataset.available == "true") {
      event.item.dataset.added = false
    } else {
      event.item.dataset.added = true
    }
  }
}
