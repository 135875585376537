// for selecting and submitting items based on class, etc, NOT checkbox status

import { Controller } from "@hotwired/stimulus"

export default class SelectableController extends Controller {
  static targets = ["selectedsContainer", "hiddenIds"]

  setupMultipleIds(event) {
    let theIds = []
    let stopSubmit = false
    if (event.params.confirm) {
      if (!confirm(event.params.message)) {
        stopSubmit = true
      }
    }

    if (stopSubmit) {
      this.dispatch("cancelled")
      event.preventDefault()
      event.stopPropagation()
    } else {
      this.selectedsContainerTarget.querySelectorAll(".selected").forEach(item => {
        theIds.push(item.dataset.itemId)
      })
    }
    this.hiddenIdsTarget.value = theIds.join("||")
    this.hiddenIdsTarget.closest("form").submit()
  }
}

