import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkBox", "sourceControl", "control"]

  useForBoth() {
    this.controlTargets.forEach(cntrl => {
      cntrl.value = this.sourceControlTarget.value
    })
  }
}
