import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["solenoidControls", "message", "noController", "touchScreen", "touchScreenSMF"];
  static values = {
    ismf: Boolean
  }

  connect() {
    this.changePulseController();
  }

  changePulseController() {
    const selectionDisablesSolenoids =
      document.querySelectorAll("input[data-disabled-solenoids=true]:checked").length > 0;
    if (selectionDisablesSolenoids) {
      this.solenoidControlsTarget.querySelectorAll("input").forEach((el) => el.disabled = true);
      this.messageTarget.classList.remove("collapse")
    } else {
      this.solenoidControlsTarget.querySelectorAll("input").forEach((el) => el.disabled = false);

      this.messageTarget.classList.add("collapse")
    }

    if (this.noControllerTarget.checked) {
      this.showTwentyFours()
    } else {
      this.hideTwentyFours()
    }

    if (this.ismfValue) {
      if (this.touchScreenTarget.checked) {
        this.noControllerTarget.checked = true
      }
      this.touchScreenTarget.disabled = true
    }
  }

  showTwentyFours() {
    this.solenoidControlsTarget.querySelectorAll("[data-twenty-four=true]").forEach((el) => {
      el.closest(".form-check").classList.remove("collapse")
    })
  }

  hideTwentyFours() {
    this.solenoidControlsTarget.querySelectorAll("[data-twenty-four=true]").forEach((el) => {
      el.checked = false;
      el.closest(".form-check").classList.add("collapse");
    })
  }
}
