import { Controller } from "@hotwired/stimulus"

export default class InitialsController extends Controller {
  static targets = ["textField"]

  setInitials(event) {
    event.preventDefault()
    let inits = event.params.initials + '-'
    let dte = new Date()
    let dtes = dte.getFullYear()
    let mnth = dte.getUTCMonth() + 1

    if (String(mnth).length < 2)
      mnth = "0" + mnth
    dtes += "" + mnth
    let day = "" + dte.getUTCDate()

    if (day.length < 2)
      day = "0" + day

    dtes += day
    this.textFieldTarget.value = inits + dtes
  }
}
