import { Controller } from "@hotwired/stimulus"

export default class EnablesController extends Controller {
  static targets = ["enabler", "enablee"]
  static values = {
    numberChecked: { type: Number, default: 0 },
    debug: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    uncheck: { type: Boolean, default: false }
  }

  connect() {
    if (this.simpleValue) {
      const cb = this.element.querySelector("input[type=checkbox]")
      cb.dataset.enablesTarget = "enabler"
      if (cb.dataset.action != "") {
        cb.dataset.action += " enables#toggle"
      } else {
        cb.dataset.action = "enables#toggle"
      }

      const enablees = this.element.querySelectorAll("input[type=text], input[type=number], input[type=radio]")
      enablees.forEach(el => {
        el.dataset.enablesTarget = "enablee"
      })
    }


    this.toggle()
  }

  numberCheckedValueChanged(_current, old) {
    if (old === undefined) // don't run on startup
      return

    this.enableeTargets.forEach(enablee => {
      enablee.disabled = this.numberCheckedValue == 0

      if (this.uncheckValue && enablee.disabled && enablee.checked)
        enablee.checked = false
    })
  }

  toggle() {
    let number = 0
    this.enablerTargets.forEach(enabler => {
      if (enabler.checked)
        number++
    })
    this.numberCheckedValue = number
  }
}
