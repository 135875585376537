import { Controller } from "@hotwired/stimulus"

export default class ExclusiveController extends Controller {
  static targets = ["checkbox"]
  static values = {
    auto: { type: Boolean, default: true }
  }

  connect() {
    if (!this.autoValue)
      return

    this.checkboxTargets.forEach(checkbox => {
      checkbox.dataset.action += " exclusive#reconcile"
    })
  }

  reconcile(event) {
    let justCheckedBox = event.target
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.id != justCheckedBox.id)
        checkbox.checked = false
    })
    this.dispatch("finished")
  }
}
