import { Controller } from "@hotwired/stimulus"

export default class DisablesOnLoadController extends Controller {
  static values = {
    check: Boolean,
    node: String,
    message: String
  }

  connect() {
    if (this.checkValue == "" || this.nodeValue == "")
      return

    let controlledElements = document.querySelectorAll(this.nodeValue)
    if (controlledElements.length == 0)
      return

    controlledElements.forEach(element => {
      element.disabled = this.checkValue

      if (this.messageValue != "")
        element.closest("div").insertAdjacentHTML("beforeend", this.prettyMessage())
    })
  }

  prettyMessage() {
    return `<span class="p-1 my-2 text-muted fst-italic">${this.messageValue}</span>`
  }
}
