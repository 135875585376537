
import { Controller } from "@hotwired/stimulus"

export default class UsabilityGroupController extends Controller {
  static targets = ["control"]

  connect() {
    let checkedEls = this.element.querySelectorAll("input[data-usability-group-selector-param]:checked")
    checkedEls.forEach(checkedItem => checkedItem.dispatchEvent(new CustomEvent("input")) )
  }

  toggle(event) {
    this.controlTargets.forEach(control => control.disabled = true)
    let controlsToToggle = this.element.querySelectorAll(event.params.selector)
    controlsToToggle.forEach(control => control.disabled = !event.target.checked )
  }
}