import { Controller } from "@hotwired/stimulus"

export default class HideController extends Controller {
  static targets = ["toggler", "togglee"]
  static values = {
    animateOut: { type: Boolean, default: true },
    anyChecked: { type: Boolean, default: false },
    nodes: { type: String, default: "" }
  }

  connect() {
    this.togglerTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.hideNodesParam).forEach(togglee => {
        togglee.classList.add("animate__animated", "animate__faster")
        togglee.addEventListener("animationend", () => {
          if (togglee.classList.contains("animate__fadeOut"))
            togglee.classList.add("d-none")
        })
      })
    })

    this.hide()

    this.toggleeTargets.forEach(togglee => {
      togglee.classList.add("animate__animated", "animate__faster")
      togglee.addEventListener("animationend", () => {
        if (togglee.classList.contains("animate__fadeOut"))
          togglee.classList.add("d-none")
      })
    })
  }

  hide() {
    if (this.anyCheckedValue) {
      let anyChecked = false
      this.togglerTargets.forEach(toggler => {
        if (toggler.checked)
          anyChecked = true
      })

      if (anyChecked)
        document.querySelectorAll(this.nodesValue).forEach(element => element.classList.add("d-none"))

      return
    }

    this.togglerTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.hideNodesParam).forEach(element => {
        if (toggler.checked) {
          if (this.animateOutValue) {
            element.classList.add("animate__fadeOut")
          } else {
            element.classList.add("d-none")
          }
        } else {
          element.classList.remove("animate__fadeOut", "d-none")
          element.classList.add("animate__fadeIn")
        }
      })
    })
  }

  linkHide(event) {
    event.preventDefault()
    this.toggleeTarget.classList.add("d-none")
  }
}
