import { Controller } from "@hotwired/stimulus"

export default class EffectsController extends Controller {
  static targets = ["item"]
  static values = {
    flashColor: { type: String, default: "bg-highlight" },
    duration: { type: String, default: "100ms" },
    loading: { type: Boolean, default: true }
  }

  itemTargetConnected(element) {
    if (this.loadingValue)
      return

    let evt = { target: element, detail: { element: element }, params: { } }
    this.flash(evt)
  }

  connect() {
    this.loadingValue = false
  }

  flash(event) {
    let element = event.target
    if (event.detail.element)
      element = event.detail.element

    if (event.params.selector)
      element = document.querySelector(event.params.selector)

    element.classList.add("flash")
  }
}
