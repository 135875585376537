import { Controller } from "@hotwired/stimulus"

export default class TextController extends Controller {
  static values = {
    temporary: { type: Boolean, default: false },
    html: { type: Boolean, default: false },
  }

  replaceText(text) {
    if (this.htmlValue) {
      this.element.insertAdjacentHTML("beforeEnd", text)
    } else {
      this.element.innerText = text
    }

    if (this.temporaryValue)
      setTimeout(() => this.element.innerText = "", 800)
  }
}
