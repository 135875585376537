import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static values = {
    settingSelectors: { type: String, default: "" }
  }

  setCookie(event) {
    let cookieName = event.params.name
    let cookieTrue = `${cookieName}=true`
    let cookieFalse = `${cookieName}=false`
    if (document.cookie.includes(cookieTrue)) {
      document.cookie = cookieFalse
    } else {
      document.cookie = cookieTrue
    }
  }
}
