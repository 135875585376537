import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["titleField", "descriptionField"]

  linkTemplate(e) {
    e.preventDefault()
    let link = e.target
    const ttl = link.nextElementSibling.textContent
    const desc = link.nextElementSibling.nextElementSibling.textContent
    this.titleFieldTarget.value = ttl
    this.descriptionFieldTarget.value = desc.replace(/  /g, "")
    this.titleFieldTarget.focus()
  }
}
