import { Controller } from "@hotwired/stimulus"

export default class MessageController extends Controller {
  static targets = ["messenger", "message"]

  static values = {
    outsideMessenger: { type: Boolean, default: true }
  }

  connect() {
    this.reconcileMessage()
  }

  reconcileMessage() {
    let result = false
    let messengers = Array.from(this.messengerTargets)
    messengers.forEach(messenger => {
      if (messenger.checked) result = true
    })

    if (result && this.outsideMessengerValue)
      this.showMessage()
    else
      this.hideMessage()
  }

  showMessage() {
    this.messageTarget.classList.remove("d-none")
  }

  hideMessage() {
    this.messageTarget.classList.add("d-none")
  }
}
