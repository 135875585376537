import { Controller } from "@hotwired/stimulus"

export default class GspController extends Controller {
  static targets = ["gsp2", "gsp4", "gsp6", "blower1k", "blower2k", "blower3k", "blowers", "models"]

  connect() {
    this.reconcileBlowerConfigurations()
  }

  reconcileBlowerConfigurations() {
    let selectedModel = this.modelsTarget.querySelector("input:checked")
    this.blower1kTarget.disabled = selectedModel.dataset.blower1k != "true"
    this.blower2kTarget.disabled = selectedModel.dataset.blower2k != "true"
    this.blower3kTarget.disabled = selectedModel.dataset.blower3k != "true"

    if (this.blowersTarget.querySelector("input:checked").disabled) {
      if (this.gsp6Target.checked) {
        this.blower3kTarget.checked = true
      } else {
        this.blowersTarget.querySelector("input:enabled").checked = true
      }
    }
  }
}
