import { Controller } from "@hotwired/stimulus"

export default class ShowController extends Controller {
  static targets = ["toggler", "togglee"]

  connect() {
    this.togglerTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.showNodesParam).forEach(togglee => {
        togglee.classList.add("animate__animated", "animate__faster")
        togglee.addEventListener("animationend", () => {
          if (togglee.classList.contains("animate__fadeOut"))
            togglee.classList.add("d-none")
        })
      })
    })

    this.show()
  }

  show() {
    this.togglerTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.showNodesParam).forEach(element => {
        if (toggler.checked) {
          element.classList.remove("animate__fadeOut", "d-none")
          element.classList.add("animate__fadeIn")
        } else {
          element.classList.add("animate__fadeOut")
        }
      })
    })
  }

  toggle(event) {
    this.toggleeTargets.forEach(togglee => {
      if (event.target.checked) {
        togglee.classList.remove("animate__fadeOut", "d-none")
        togglee.classList.add("animate__fadeIn")
      } else {
        togglee.classList.add("animate__fadeOut")
      }
    })
  }
}
