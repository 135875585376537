import { Controller } from "@hotwired/stimulus"

export default class TerritoriesController extends Controller {
  static targets = ["massAssignmentForm", "newOrder", "office"]

  addTerritory(event) {
    event.preventDefault()
    let newAssignee = event.target.closest(".result").cloneNode(true)
    let target = document.querySelector(`.${event.params.role}s`)
    newAssignee.classList.remove("result")
    newAssignee.classList.add("office")
    newAssignee.dataset.territoriesTarget = "office"
    target.append(newAssignee)
    event.target.insertAdjacentHTML("afterEnd", "<span class='ms-2 mark'>Added!</span>")
  }

  submitMassForm(event) {
    if (this.hasOfficeTarget) {
      let vals = []
      this.officeTargets.forEach(office => vals.push(office.dataset.officeId))
      this.newOrderTarget.value = vals
    } else {
      event.preventDefault()
      alert("You must add at least one assignment for the zip code(s)")
      return
    }
  }
}
