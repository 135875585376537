document.addEventListener('turbo:load', () => {
  // copy template
  document.querySelectorAll('[data-custom-item-template]').forEach((template) => {
    template.addEventListener('click', (event) => {
      event.preventDefault();
      const desc = decodeURIComponent(event.target.dataset.description);
      const descFormatted = desc.replace(RegExp(/\+/, "g"), " ");
      document.querySelector('#custom_item_description').value = descFormatted;
    })
  })

  // copy user's description
  document.querySelectorAll('[data-custom-item-title]').forEach((title) => {
    title.addEventListener('click', (event) => {
      event.preventDefault();

      // use description
      const desc = decodeURIComponent(event.target.dataset.description);
      const descFormatted = desc.replace(RegExp(/\+/, "g"), " ");
      document.querySelector('#custom_item_description').value = descFormatted;

      // use price
      const prc = event.target.dataset.price;
      if (parseInt(prc) > 0 && document.querySelector('#custom_item_price'))
        document.querySelector('#custom_item_price').value = prc;
    })
  })
})
