document.addEventListener('turbo:load', function() {
  let models = document.querySelectorAll('[data-zephyr-models="true"] input[type=radio]')
  let hepaControls = document.querySelectorAll('#zephyr_hepa_filter, #zephyr_swing_bolt')

  models.forEach((model) => {
    model.addEventListener('change', () => {
      if (model.dataset.hepa == 'true') {
        hepaControls.forEach((el) => {
          el.disabled = false
        })
      } else {
        hepaControls.forEach((el) => {
          el.disabled = true
          el.checked = false
        })
      }
    })
  })

  let checked = document.querySelector('[data-zephyr-models="true"] input:checked')
  if (checked) {
    checked.dispatchEvent(new Event('change'))
  }
})
