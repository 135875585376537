import { Controller } from "@hotwired/stimulus"

export default class EuropeXventController extends Controller {
  static targets = ["cowl", "duct", "explosionVent", "hiPressureDoor"]

  static values = {
    europe: { type: Boolean, default: false },
    gsBibo: { type: Boolean, default: false }
  }

  connect() {
    this.reconcileXvents()
    this.reconcileDucts()
  }

  reconcileXvents() {
    if (this.explosionVentTarget.checked) {
      if (this.europeValue && this.hasHiPressureDoorTarget && !this.gsBiboValue)
        this.hiPressureDoorTarget.checked = true
    }
  }

  reconcileDucts() {
    const ductsInUse = this.anyDuctsSelected()
    this.cowlTargets.forEach((cowl) => {
      if (ductsInUse) {
        cowl.disabled = true
        cowl.checked = false
      } else {
        cowl.disabled = false
      }
    })
  }

  anyDuctsSelected() {
    let checked = false

    this.ductTargets.forEach((duct) => {
      if (duct.checked)
        checked = true
    })

    return checked
  }
}
