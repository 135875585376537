import { Controller } from "@hotwired/stimulus"

export default class UncheckController extends Controller {
  static targets = ["unchecker", "uncheckee"]
  static outlets = ["visibility"]
  static values = {
    controlVisibility: { type: Boolean, default: false },
    connecting: { type: Boolean, default: false }
  }

  connect() {
    this.connectingValue = true
    this.uncheck()
    this.connectingValue = false
  }

  uncheck() {
    this.uncheckerTargets.forEach(unchecker => {
      if (unchecker.checked) {
        document.querySelectorAll(unchecker.dataset.uncheckNodesParam).forEach(element => element.checked = false)
        if (!this.connectingValue && this.controlVisibilityValue && this.hasVisibilityOutlet) {
          this.visibilityOutlet.toggleNodes()
        }
      } else { // not checked
        document.querySelectorAll(unchecker.dataset.uncheckWhenNotCheckedNodesParam).forEach(element => element.checked = false)
      }
    })
  }
}
