import { Controller } from "@hotwired/stimulus"

export default class GsOptionsController extends Controller {
  static targets = ["ismf", "ismfFullCoverage", "ismfHandrail", "ladder", "calculateVelocity", "customInlet",
                    "velocityWarningNote", "velocityDangerNote", "velocityDisplay", "highEntryInlet",
  "inletRequiredMessage"]
  static values = {
    metric: { type: Boolean, default: false },
    inletVelocity: { type: Number, default: 0 },
    airflow: Number,
    velocityColor: { type: String, default: "#333F48" }
  }
  static classes = ["velocityWarning", "velocityOk", "velocityDanger", "hide"]

  connect() {
    if (!this.hasCustomInletTarget)
      return

    if (this.customInletTarget.checked) {
      this.calculateVelocity()
    }

    if (this.ladderTarget.checked)
      this.reconcileIsmf(true)
  }

  checkValid() {
    if (!this.hasCustomInletTarget) // mining
      return true

    let checkedInlets = this.element.querySelectorAll("input.actual-inlet:checked").length
    if (this.customInletTarget.checked && checkedInlets == 0) {
      this.inletRequiredMessageTarget.classList.remove("d-none")
      let rect = this.inletRequiredMessageTarget.getBoundingClientRect()
      window.scrollTo(0, rect.top + window.scrollY - 250)
      return false
    } else {
      this.inletRequiredMessageTarget.classList.add("d-none")
      return true
    }
  }

  rectifyIsmf() {
    this.reconcileIsmf()
  }

  reconcileIsmf(loading = false) {
    if (!this.hasIsmfHandrailTarget)
      return

    if (this.ismfChecked() && this.ladderTarget.checked) {
      this.ismfHandrailTarget.disabled = false
      if (!loading)
        this.ismfHandrailTarget.checked = true
    } else {
      this.ismfHandrailTarget.disabled = true
      this.ismfHandrailTarget.checked = false
    }
  }

  ismfChecked() {
    let result = false
    this.ismfTargets.forEach(ismf => {
      if (ismf.checked)
        result = true
    })
    return result
  }

  calculateVelocity() {
    let totalArea = 0.0

    this.calculateVelocityTargets.forEach(inlet => {
      if (!inlet.checked)
        return

      let area = inlet.dataset.area
      let qty = inlet.closest("[data-calculate]").querySelector("input[type=number]").value
      totalArea += qty * area
    })

    let airflow = this.airflowValue
    this.inletVelocityValue = (airflow / totalArea).toFixed(2)
  }

  inletVelocityValueChanged() {
    let velocityDisplay = document.querySelector("#inlet_velocity")
    if (!velocityDisplay) // mining
      return
    velocityDisplay.classList.value = ""
    velocityDisplay.textContent = this.inletVelocityValue

    let afLow, afHi
    if (this.metricValue) {
      afLow = 45720
      afHi = 54864
    } else {
      afLow = 2500
      afHi = 3000
    }

    this.velocityDangerNoteTarget.classList.add(this.hideClass)
    this.velocityWarningNoteTarget.classList.add(this.hideClass)

    if (this.inletVelocityValue >= afHi) {
      this.velocityDisplayTarget.classList.add(...this.velocityDangerClasses)
      this.velocityDangerNoteTarget.classList.remove(this.hideClass)
    } else if (this.inletVelocityValue > afLow && this.inletVelocityValue < afHi) {
      this.velocityWarningNoteTarget.classList.remove(this.hideClass)
      this.velocityDisplayTarget.classList.add(...this.velocityWarningClasses)
    } else {
      this.velocityDisplayTarget.classList.add(...this.velocityOkClasses)
    }
  }
}
