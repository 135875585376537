import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["list", "itemsField", "placeHolder"]
  static values = {
    submitAll: Boolean,
    debug: Boolean,
    autoSubmit: { type: Boolean, default: false },
    message: String
  }

  connect() {
    this.listTargets.forEach((list) => {
      let hndleClass = list.querySelectorAll(".handle").length > 0 ? ".handle" : ""
      Sortable.create(list, {
        animation: 150,
        group: "collector",
        handle: hndleClass,
        onEnd: this.submitAuto.bind(this)
      })
    })

    if (!this.hasItemsFieldTarget)
      return

    let form = this.itemsFieldTarget.closest("form")
    if (this.messageValue) {
      if (form.dataset.action) {
        form.dataset.action += " ajax:success->arrange#notifySuccess"
      } else {
        form.dataset.action = "ajax:success->arrange#notifySuccess"
      }
    }

  }

  notifySuccess(_event) {
    this.placeHolderTargets.forEach(placeHolder => {
      placeHolder.insertAdjacentText("afterbegin", this.messageValue)
    })

    setTimeout(() => {
      this.placeHolderTargets.forEach(placeHolder => {
        placeHolder.innerHTML = ""
      })
    }, 2000)
  }

  submitAll(event) {
    this.setupDataForSubmit()

    if (this.debugValue)
      event.preventDefault()
  }

  // this is only a stimulus event b/c of .bind(this) above
  submitAuto(event) {
    if (!this.autoSubmitValue)
      return

    if (this.hasItemsFieldTarget) {
      let form = this.itemsFieldTarget.closest("form")
      this.setupDataForSubmit()
      Rails.fire(form, "submit")

      return
    }

    let url = event.to.dataset.url || event.target.closest("form").action
    let data = []
    let method = event.to.dataset.method || "post"
    event.to.querySelectorAll("[data-list-item-id]").forEach(itm => data.push(itm.dataset.listItemId))
    const request = new FetchRequest(method, url, { query: { items: data }})
    request.perform().then((response) => {
      if (response.ok && this.messageValue)
        this.notifySuccess()
    })
  }

  setupDataForSubmit() {
    let data = []
    document.querySelectorAll("[data-list-item-id]").forEach(itm => data.push(itm.dataset.listItemId))
    this.itemsFieldTarget.value = data
  }

  submitCollector() {
    let electives = []
    let combined = []

    this.listTargets.forEach((list) => {
      list.querySelectorAll("li .item-title span").forEach((span) => {
        if (list.dataset.arrangeListElective == "true") {
          electives.push(span.dataset.code)
        } else {
          combined.push(span.dataset.code)
        }
      })
    })

    document.querySelector("#elective").value = electives
    document.querySelector("#combined").value = combined
  }

  swapAll(event) {
    event.preventDefault()

    let list = document.querySelector(`#${event.params.list}`)
    let oppositeList = document.querySelector(`#${event.params.opposite}`)

    list.querySelectorAll('li').forEach(li => {
      li.classList.add('animate__fadeOut')
      oppositeList.append(li)
      li.classList.add('animate__fadeIn')
    })

    oppositeList.querySelectorAll('li').forEach(li => {
      li.classList.add('animate__fadeIn')
      li.classList.remove('animate__fadeOut')
    })
  }
}
