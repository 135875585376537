const EMEO_MODEL = '[data-emeo-model="true"]'
const EMEO_NO_FOUR = '[data-emeo-no-four="true"]'
const EMEO_THREE_STAGE = '[data-emeo-four-stage="false"]'
const EMEO_FOUR_STAGE = '[data-emeo-four-stage="true"]'

document.addEventListener('turbo:load', () => {
  if (!document.querySelector(EMEO_MODEL))
    return

  const mdls = document.querySelectorAll(EMEO_MODEL)
  mdls.forEach((mdl) => {
    mdl.addEventListener('change', () => {
      if (document.querySelector(EMEO_NO_FOUR).checked) {
        const fourStage = document.querySelector(EMEO_FOUR_STAGE)
        fourStage.checked = false
        fourStage.disabled = true
        document.querySelector(EMEO_THREE_STAGE).checked = true
      } else {
        document.querySelector(EMEO_FOUR_STAGE).disabled = false
      }
    })
  })
  document.querySelector(EMEO_MODEL + ':checked').dispatchEvent(new Event('change'))
})
