import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['highElevationFan', 'highPressurePump'];

  connect() {
    this.changeFluidReturn();
  }

  changeVoltage(event) {
    let currentVoltage = event.target;

    if (currentVoltage.id.includes('60')) {
      this.highElevationFanTarget.disabled = false;
    } else {
      this.highElevationFanTarget.checked = false;
      this.highElevationFanTarget.disabled = true;
    }
  }

  changeFluidReturn() {
    let currentFluidReturn = document.querySelector('[data-fluid-returns] input:checked');

    if (currentFluidReturn.dataset.siphon == 'true') {
      this.highPressurePumpTarget.disabled = true;
      this.highPressurePumpTarget.checked = false;
    } else {
      this.highPressurePumpTarget.disabled = false;
    }
  }
}
