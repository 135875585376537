import { Controller } from "@hotwired/stimulus"

export default class TitleController extends Controller {
  change(event) {
    let option = event.target.querySelector("option:checked")
    let ttl

    if (option) {
      ttl = option.textContent
    } else { // radio button
      ttl = event.params.text
    }

    document.title = ttl
  }
}
