import { Controller } from "@hotwired/stimulus"

export default class CustomDescriptionController extends Controller {
  static targets = ["description"]

  displayForm(event) {
    let xhr = event.detail[2].response
    this.element.querySelectorAll("[data-custom-description-form]").forEach(el => el.remove())
    event.target.insertAdjacentHTML("afterend", xhr)
  }

  create(event) {
    let element = event.target.closest("[data-controller=custom-description]")
    element.insertAdjacentHTML("afterend", event.detail[2].response)
    element.remove()
  }

  edit(event) {
    let element = event.target.closest("[data-controller=custom-description]")
    element.insertAdjacentHTML("beforeend", event.detail[2].response)
  }

  delete(event) {
    let element = event.target.closest("[data-controller=custom-description]")
    element.insertAdjacentHTML("afterend", event.detail[2].response)
    element.remove()
    event.stopPropagation()
  }
}
