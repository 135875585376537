let hopperWarning = function() {
  let sludgeHopper = document.querySelector('[data-sludge-hopper="true"]');
  let sludgeWarning = document.querySelector('[data-sludge-warning="true"]');
  let controlsThatWarnChecked = document.querySelector('[data-hopper-warn="true"]:checked');

  if (sludgeHopper && controlsThatWarnChecked) {
    sludgeWarning.classList.add('collapse')
  } else {
    sludgeWarning.classList.remove('collapse')
  }
}

document.addEventListener('turbo:load', function() {
  if (document.querySelector('[data-sludge-hopper]')) {
    hopperWarning();
  }

  document.querySelectorAll('[data-hopper-warn]').forEach((cntrl) => {
    cntrl.addEventListener('change', function() {
      hopperWarning();
    })
  })
})
