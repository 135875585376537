import { Controller } from "@hotwired/stimulus"

export default class QuoteElementController extends Controller {
  displayElementForm(event) {
    let xhr = event.detail[2].response
    this.element.querySelectorAll(`[data-quote-string-code="${event.params.quoteStringCode}"]`).forEach(el => el.remove())
    event.target.insertAdjacentHTML("afterend", xhr)
  }

  create(event) {
    let element = event.target.closest("[data-controller=quote-element]")
    element.insertAdjacentHTML("afterend", event.detail[2].response)
    element.remove()
  }

  edit(event) {
    let element = event.target.closest("[data-controller=quote-element]")
    element.insertAdjacentHTML("beforeend", event.detail[2].response)
  }

  default(event) {
    event.preventDefault()
    let txtControl = event.target.closest("form").querySelector("input[type=text]")
    txtControl.value = event.params.defaultText
  }
}
