import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["xvent", "coverFlat", "plenumAngled", "duct", "ductHorizontal",
                    "plenum", "flatCoverMessage", "xventPositiveWarning", "horizontalHood", "hood"]

  static values = {
    positivePressure: { type: Boolean, default: false },
  }

  connect() {
    this.reconcileXvents()
    this.resolveFlatCovers()
  }

  reconcileXvents() {
    let positiveWarningRequired = !(this.xventTarget.checked && this.positivePressureValue)
    this.xventPositiveWarningTarget.classList.toggle("d-none", positiveWarningRequired)
  }

  reconcileHorizontalHood() {
    if (this.horizontalHoodTarget.checked) {
      this.plenumTarget.checked = false
      this.hoodTarget.checked = false
    }
  }

  reconcilePlenum() {
    if (this.plenumTarget.checked) {
      this.horizontalHoodTarget.checked = false
      this.hoodTarget.checked = true
      this.ductTarget.checked = true
    } else {
      this.hoodTarget.checked = false
    }

  }

  resolveFlatCovers() {
    if (this.plenumTarget.checked) {
      this.disableFlatCovers()
      return false
    }

    if (this.xventTarget.checked || this.plenumAngledTarget.checked || this.ductHorizontalTarget.checked) {
      this.coverFlatTarget.disabled = false
      if (this.flatCoverRequired())
        this.coverFlatTarget.checked = true
    } else {
      this.disableFlatCovers()
    }
  }

  ensureOneFlatCover() {
    if (this.flatCoverRequired()) {
      this.coverFlatTarget.checked = true
      this.flatCoverMessageTarget.classList.remove('collapse')
    }
  }

  disableFlatCovers() {
    this.coverFlatTarget.disabled = true
    this.coverFlatTarget.checked = false
  }

  flatCoverRequired() {
    return this.xventTarget.checked && this.plenumAngledTarget.checked
  }
}
