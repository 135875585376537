import { Controller } from "@hotwired/stimulus"

export default class UsabilityController extends Controller {
  static targets = ["toggler"]

  connect() {
    this.toggleNodes()
  }

  toggleNodes() {
    let nodes = document.querySelectorAll(this.togglerTarget.dataset.usabilityNodesParam)
    nodes.forEach(node => {
      if (!this.togglerTarget.checked)
        node.checked = false
      node.disabled = !this.togglerTarget.checked
    })
    this.dispatch("toggleFinished")
  }
}
