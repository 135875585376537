import { Controller } from "@hotwired/stimulus"

export default class SimpleDisableController extends Controller {
  static targets = ["disabler", "disablee"]

  connect() {
    this.toggleNodes()
  }

  toggleNodes() {
    this.disablerTargets.forEach(toggler => {
      let disablees = document.querySelectorAll(toggler.dataset.simpleDisableNodesParam)
      disablees.forEach(disablee => {
        if (toggler.checked)
          disablee.disabled = true
      })
    })
  }
}
