import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['model', 'mainFilters'];

  initialize() {
    this.changeModel()
  }

  changeModel() {
    const checkedModel = document.querySelector('.models input:checked');
    this.resolvePreSeparator(checkedModel);
    this.resolveThreeStage(checkedModel);
  }

  resolvePreSeparator(model) {
    if (model.dataset.preSeparator == 'true') {
      this.mainFiltersTarget.querySelectorAll('[data-pre-separator="true"]').forEach((el) => el.disabled = false);
    } else {
      this.mainFiltersTarget.querySelectorAll('[data-pre-separator="true"]').forEach((el) => {
        el.checked = false;
        el.disabled = true;
      });
    }
  }

  resolveThreeStage(model) {
    if (model.dataset.threeStage == 'true') {
      this.mainFiltersTarget.querySelectorAll('[data-three-stage="true"]').forEach((el) => el.disabled = false);
    } else {
      this.mainFiltersTarget.querySelectorAll('[data-three-stage="true"]').forEach((el) => {
        el.checked = false;
        el.disabled = true;
      });
    }
  }
}
