import { Controller } from "@hotwired/stimulus"

export default class CheckUsabilityOppositeController extends Controller {
  static targets = ["disabler"]
  static values = {
    nodes: String
  }

  disablerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "check-usability-opposite#toggleNodes"
    } else {
      target.dataset.action += " check-usability-opposite#toggleNodes"
    }
  }

  connect() {
    setTimeout(() => {
      this.disablerTargets.forEach(disabler => {
        this.toggleNodes({ target: { checked: disabler.checked }})
      })
    }, 333)
  }

  toggleNodes(event) {
    document.querySelectorAll(this.nodesValue).forEach(node => {
      if (event.target.checked) {
        node.disabled = true
      } else {
        node.disabled = false
      }
    })
  }
}
