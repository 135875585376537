import { Controller } from "@hotwired/stimulus"

export default class TechServiceSpecialsController extends Controller {
  static targets = ["userIds"]

  removeWarnings() {
    document.querySelectorAll(".alert-warning").forEach(warning => warning.classList.add("d-none"))
  }

  checkValid() {
    let users = document.querySelectorAll("fieldset a[data-added-to-list]")
    if (users.length < 1) {
      let warning = document.querySelector("[data-warning-user-required]")
      if (warning)
        warning.classList.remove("d-none")
      return false
    }
    return true
  }

  setupUsers() {
    let ids = []
    document.querySelectorAll("a[data-added-to-list]").forEach(user => ids.push(user.dataset.userId))
    this.userIdsTarget.value = ids.join()
  }
}
