import { Controller } from "@hotwired/stimulus"

export default class CheckController extends Controller {
  static targets = ["checker", "checkerOnce"]
  static values = {
    loading: Boolean
  }

  connect() {
    this.check()
  }

  check() {
    this.checkerTargets.forEach(checker => {
      document.querySelectorAll(checker.dataset.checkSelectorParam).forEach(checkee => {
        if (checker.checked)
          checkee.checked = true
      })
    })
  }

  checkOnce() {
    this.checkerOnceTargets.forEach(checker => {
      document.querySelectorAll(checker.dataset.checkOnceSelectorParam).forEach(checkee => {
        if (checker.checked)
          checkee.checked = true
      })
    })
  }

  checkTheControls() {

  }
}
