import { Controller } from "@hotwired/stimulus"

export default class LinkHideController extends Controller {
  static values = {
    nodes: String,
    nodesByElement: String
  }

  hideNodes(event) {
    event.preventDefault()
    let hidees = document.querySelectorAll(this.nodesValue)
    hidees.forEach(hidee => hidee.classList.add("d-none"))
  }

  hideNodesByElement(event) {
    event.preventDefault()
    let mainElement = document.querySelector(this.nodesByElementValue)
    let nodesToHide = mainElement.dataset.linkHideNodesParam
    document.querySelectorAll(nodesToHide).forEach(node => {
      node.classList.add("d-none")
    })
  }
}
