import { Controller } from "@hotwired/stimulus"

export default class ModelController extends Controller {
  static targets = ["model"]

  static values = {
    prefix: String
  }

  connect() {
    this.modelTargets.forEach(model => {
      if (model.dataset.action === undefined) {
        model.dataset.action = "model#modelChange"
      } else {
        model.dataset.action += " model#modelChange"
      }

      if (model.checked)
        model.dispatchEvent(new CustomEvent("input", { bubbles: true }))
    })
  }

  // incoming event params ending with "auto_param" will toggle that control by id
  modelChange(event) {
    for (const [item, state] of Object.entries(event.params)) {
      if (!item.includes("Auto"))
        continue

      if (state === "")
        continue

      let selector = `${this.prefixValue}_${this.camelToSnakeCase(item.replace("Auto", ""))}`
      let element = document.getElementById(selector)
      if (!state) {
        element.disabled = true
        element.checked = false
      } else {
        element.disabled = false
      }
    }
  }

  camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }
}
