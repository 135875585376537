import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["toField", "fromField", "submit", "fieldsSource", "fieldsDestination", "focusField",
                    "escapeField", "escaper"];
  static values = {
    preventInvalidInput: { type: Boolean, default: false },
    autoSubmit: { type: Boolean, default: false },
    simpleReturn: { type: Boolean, default: false },
    debug: { type: Boolean, default: false }
  }

  static outlets = ["text", "dismiss"]

  connect() {
    const dateInputs = this.element.querySelectorAll("[data-flatpickr]")
    if (!dateInputs)
      return

    dateInputs.forEach(input => {
      input.onkeypress = () => false;
      flatpickr(input, { allowInput: true, altInput: false });
    })

    this.escapeFieldTargets.forEach(field => {
      if (field.dataset.action) {
        field.dataset.action += " keydown.esc->forms#escape"
      } else {
        field.dataset.action = "keydown.esc->forms#escape"
      }
    })

    this.focusField()
  }

  submit() {
    this.element.requestSubmit()
  }

  formSuccess(e) {
    let xhr = e.detail[2]
    e.target.insertAdjacentHTML('afterend', `<p class="alert alert-info">${xhr.response}</p>`)
    e.target.remove()
  }

  copyField() {
    this.toFieldTarget.value = this.fromFieldTarget.value
  }

  fillFields(event) {
    event.preventDefault()

    let elements = []
    let values = event.params.fillValues.split("||")

    event.params.fillSelectors.split("||").forEach(selector => elements.push(document.querySelector(selector)))
    elements.forEach((element, ndx) => element.value = values[ndx])
  }

  requireCheckGroupForSubmit(event) {
    let checkedSelector = event.params.groupSelector + ":checked"
    this.submitTarget.disabled = this.element.querySelectorAll(checkedSelector).length < 1
  }

  async submitForm(event) {
    event.preventDefault()

    const formData = new FormData(this.element)
    const request = new FetchRequest(this.element.method, this.element.action, { body: formData })
    const response = await request.perform()
    if (!response.ok) {
      console.log(`Problem: ${response}`)
    } else {
      let returnVal = await response.text

      if (this.hasDismissOutlet)
        this.dismissOutlet.remove()

      if (returnVal == "")
        return

      if (this.simpleReturnValue && this.hasTextOutlet) {
        this.textOutlet.replaceText(returnVal)
        return
      }

      const val = JSON.parse(returnVal)
      this.textOutlets.forEach(textController => {
        if (textController.element.classList.contains("project--total-price"))
          textController.replaceText(val.project_total)
        else
          textController.replaceText(val.collector_total)
      })
    }
  }

  clearAll(event) {
    event.preventDefault()
    let fieldset = event.target.closest("fieldset")
    let txtSelector = "input[type=text], input[type=tel], input[type=email]"
    fieldset.querySelectorAll(txtSelector).forEach(field => field.value = "")
    fieldset.querySelectorAll("input[type=checkbox]").checked = false
  }

  copyFields(event) {
    event.preventDefault()
    let source = event.target.closest("[data-forms-source]")
    let fields = event.params.fields.split(",")
    fields.forEach(field => {
      let value = source.querySelector(`[data-${field}]`).value
      let destField = this.fieldsDestinationTarget.querySelector(`[data-${field}]`)
      destField.value = value
    })

    this.dispatch("copyFieldsFinished")

    if (this.autoSubmitValue)
      this.submitForm(event)
  }

  checkInvalid(event) {
    this.element.querySelectorAll("input[type='text'], input[type=email], input[type=tel]").forEach(fld => {
      if (fld.value.includes("%")) {
        this.dispatch("invalid")
        event.preventDefault()
        event.stopPropagation()
        let alrt = document.createElement("div")
        alrt.classList.add("text-danger", "m-2")
        alrt.innerText = "Invalid characters"
        this.element.after(alrt)
      }
    })
  }

  useForAll(event) {
    this.element.querySelectorAll(event.params.toSelector).forEach(cntrl =>
      cntrl.value = this.fromFieldTarget.value)
  }

  focusField() {
    if (!this.hasFocusFieldTarget)
      return

    const end = this.focusFieldTarget.value.length
    this.focusFieldTarget.setSelectionRange(end, end)
    this.focusFieldTarget.focus()
  }

  escape(event) {
    if (this.hasEscaperTarget) {
      this.escaperTarget.click()
    }
  }
}
