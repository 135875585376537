import { Controller } from "@hotwired/stimulus"

export default class ClientsController extends Controller {
  static targets = ["clearSearch", "searchBox", "existsMessage",
                    "contact", "company", "checkForm", "checkContact", "checkCompany"]

  clearSearch(event) {
    event.preventDefault()
    this.clearSearchTarget.classList.add("d-none")
    document.querySelectorAll("tr:not(.d-none)").forEach(tr => tr.remove())
    this.searchBoxTarget.value = ""
    document.querySelectorAll("tr").forEach(tr => tr.classList.remove("animated__fadeOut"))
    document.querySelectorAll("tr").forEach(tr => tr.classList.remove("d-none"))
  }

  verifyExists() {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => {
      this.checkExists()
    }, 800)
  }

  checkExists() {
    let contact = this.contactTarget.value.trim()
    let company = this.companyTarget.value.trim()

    if (contact == "" || company == "")
      return

    this.checkCompanyTarget.value = company
    this.checkContactTarget.value = contact
    this.checkFormTarget.requestSubmit()
  }

  emphasizeMessage(event) {
    this.existsMessageTarget.classList.add("bg-danger-subtle", "p-1", "mt-1")
  }
}
