import { Controller } from "@hotwired/stimulus"

export default class LinkUncheckController extends Controller {
  static values = {
    nodes: String,
    dispatchEvents: { type: Boolean, default: true }
  }

  uncheckNodes() {
    let elements = document.querySelectorAll(this.nodesValue)
    elements.forEach(element => {
      element.checked = false
      if (this.dispatchEventsValue)
        element.dispatchEvent(new Event("input"))
    })
  }
}
