import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "description", "price", "techServiceSpecialId", "integratedControls",
                     "sync", "remove", "link", "silencerSupport", "models" ]

  connect() {
    this.modelChange()
  }

  modelChange() {
    const selectedModel = this.modelsTarget.querySelector("input[type=radio]:checked")
    if (!selectedModel)
      return

    if (selectedModel.dataset.fifty == "true") {
      this.silencerSupportTarget.closest(".form-check").classList.add("collapse")
      this.silencerSupportTarget.checked = false
    } else {
      this.silencerSupportTarget.closest(".form-check").classList.remove("collapse")
    }
  }

  reconcileIntegratedController() {
    if (this.descriptionTarget.value.trim() != "" && this.priceTarget.value > 0) {
      this.integratedControlsTarget.classList.remove("collapse")
    } else {
      this.integratedControlsTarget.classList.add("collapse")
    }
  }

  linkFan(event) {
    event.preventDefault()

    // set any previous Remove links to Use links
    this.removeTargets.forEach(removeLink => removeLink.classList.add("collapse"))
    this.linkTargets.forEach(linkLink => linkLink.classList.remove("collapse"))

    this.syncButton(event.target).classList.add("collapse")
    this.linkButton(event.target).classList.add("collapse")
    this.removeButton(event.target).classList.remove("collapse")

    this.techServiceSpecialIdTarget.value = event.params.techServiceSpecialId
    this.priceTarget.value = event.params.price

    this.descriptionTarget.value = ""
    const lines = event.params.description.split("\n")
    lines.forEach((line, ndx) => {
      this.descriptionTarget.value += line.trim()

      if (ndx != lines.length - 1)
        this.descriptionTarget.value += "\n"
    })

    this.priceTarget.dispatchEvent(new Event("input")) // submit form
  }

  removeSpecialFan(event) {
    event.preventDefault()

    this.priceTarget.value = 0
    this.descriptionTarget.value = ""
    this.techServiceSpecialIdTarget.value = null

    this.linkButton(event.target).classList.remove("collapse")
    this.removeButton(event.target).classList.add("collapse")
    this.syncButton(event.target).classList.add("collapse")
  }

  linkButton(target) {
    return target.closest("td").querySelector("[data-special-fan-target=link]")
  }

  removeButton(target) {
    return target.closest("td").querySelector("[data-special-fan-target=remove]")
  }

  syncButton(target) {
    return target.closest("td").querySelector("[data-special-fan-target=sync]")
  }
}
