import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import * as bootstrap from "bootstrap"

export default class extends Controller {
  async replaceHelp(event) {
    let element = event.detail.elementToChange
    let url = `/help_link/${event.detail.newCode}`
    const request = new FetchRequest("get", url, {
      query: {
        project_id: event.detail.projectId, collector_id: event.detail.collectorId
      }
    })
    const response = await request.perform()
    if (response.ok) {
      let body = await response.html
      element.querySelectorAll(".quick-help-link").forEach(element => element.remove())
      element.insertAdjacentHTML("beforeend", body)
    }
  }

  showQuickHelp(event) {
    event.target.dataset.bsContent = event.detail[2].response
    let popover = bootstrap.Popover.getOrCreateInstance(event.target)
    popover.show()
  }
}
