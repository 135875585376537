import { Controller } from "@hotwired/stimulus"

export default class GsDischargeController extends Controller {
  static targets = ['size', 'sizable', 'userSupplied', 'airlockNotRequired', 'camfilSupplied',
    "sdhWarning", "drum", "selfDumpingHopper"]
  static values = { europe: Boolean,
                    lowBoy: Boolean }

  initialize() {
    this.userSuppliedToggle();
  }

  connect() {
    this.reconcileDrums()
  }

  reconcileDrums() {
    let drumSelected = this.element.querySelectorAll("[data-gs-discharge-target*='drum']:checked").length > 0
    this.selfDumpingHopperTargets.forEach(sdh => {
      sdh.disabled = drumSelected
      if (drumSelected) sdh.checked = false
    })

    let sdhSelected = this.element.querySelectorAll("[data-gs-discharge-target*='selfDumpingHopper']:checked").length > 0
    this.drumTargets.forEach(drum => {
      drum.disabled = sdhSelected
      if (sdhSelected) drum.checked = false
    })
  }

  reconcileSize() {
    if (this.europeValue)
      return;

    this.sizeTarget.disabled = !this.sizeRequired();
  }

  sizeRequired() {
    if (this.userSuppliedTarget.checked)
      return true;

    if (this.camfilSuppliedTarget.checked)
      return false;

    if (this.lowBoyValue) {
      return false;
    }

    // not required if airlock chosen
    if (this.hasAirlockNotRequiredTarget && !this.airlockNotRequiredTarget.checked)
      return false;

    if (this.coverKitSelected())
      return false

    if (this.deviceChosen() && !this.coverKitSelected())
      return true;

    if (!this.deviceChosen()) return true;

    return false
  }

  userSuppliedToggle() {
    if (this.europeValue) {
      this.sizeTarget.disabled = !this.userSuppliedTarget.checked;
      return;
    }

    this.reconcileSize();
  }

  deviceChosen() {
    let chosen = false;

    this.sizableTargets.forEach((element) => {
      if (element.checked)
        chosen = true;
    })

    return chosen;
  }

  coverKitSelected() {
    return document.querySelectorAll('input[id^=gs_discharge_dck]:checked').length > 0;
  }
}
