import { Controller } from "@hotwired/stimulus"

export default class GsController extends Controller {
  static targets = ["filter", "stainless", "hoppers", "models", "dischargePoints",
    "hopperLowBoy", "hopperStandard", "housings", "hopperPyramids", "hopperTrough",
    "preview", "inletVelocity", "velocityNote", "velocityWarning", "lowboyUltrasonicIndicator"];

  static values = {
    measurementSystem: { type: String, default: "imperial" },
    europe: { type: Boolean, default: false },
    xflo: { type: Boolean, default: true }
  }

  connect() {
    this.filterChange()
    this.updateDischargePoints()
    this.reconcileHoppers()
    this.updateVelocity()

    if (this.hasPreviewTarget) {
      this.previewTarget.addEventListener("animationend", (event) => {
        let classList = event.target.classList
        if (classList.contains("animate__fadeOut"))
          classList.remove("animate__fadeOut")

        if (classList.contains("animatefadeIn"))
          classList.remove("animate__fadeIn")
      })
    }

    this.dispatch("connectFinished", { detail: { message: "connect" } })
  }

  modelChange() {
    this.reconcileLowBoy()
    this.updateDischargePoints()
    this.reconcileHoppers()
    this.updateVelocity()
    this.updatePreview()
    this.dispatch("modelChange", { detail: { message: "modelChange" } })
  }

  updateVelocity() {
    const velocity = this.modelsTarget.querySelector("option:checked").dataset.velocity
    this.inletVelocityTarget.textContent = velocity
    this.element.querySelectorAll("[data-velocity-warning], [data-velocity-note]").forEach(element => element.classList.add("d-none"))

    let afLow = 2500
    let afHi = 3000

    if (this.measurementSystemValue == "metric") {
      afLow = 45720
      afHi = 54864
    }

    if (velocity > afLow && velocity < afHi) {
      this.velocityNoteTarget.classList.remove("d-none")
      this.velocityWarningTarget.classList.add("d-none")
      this.inletVelocityTarget.classList.add("text-info")
      return
    }

    if (velocity >= afHi) {
      this.velocityWarningTarget.classList.remove("d-none")
      this.inletVelocityTarget.classList.remove("text-body")
      this.inletVelocityTarget.classList.add("text-danger")
      return
    }

    // all good
    this.velocityNoteTarget.classList.add("d-none")
    this.velocityWarningTarget.classList.add("d-none")
    this.inletVelocityTarget.classList.add("text-body")
  }

  reconcileLowBoy() {
    let model = this.modelsTarget.querySelector("option:checked")

    if (model.dataset.lowboy == "true") {
      this.hopperLowBoyTarget.disabled = false
      if (this.hasLowboyUltrasonicIndicatorTarget)
        this.lowboyUltrasonicIndicatorTarget.disabled = false
    } else {
      this.hopperStandardTarget.checked = true
      this.hopperLowBoyTarget.disabled = true
      if (this.hasLowboyUltrasonicIndicatorTarget) {
        this.lowboyUltrasonicIndicatorTarget.disabled = true
        this.lowboyUltrasonicIndicatorTarget.checked = false
      }
    }
  }

  reconcileHoppers() {
    const selectedModel = this.modelsTarget.querySelector("option:checked")
    this.hopperLowBoyTarget.disabled = selectedModel.dataset.lowBoy != "true"

    this.hopperPyramidsTarget.disabled = selectedModel.dataset.pyramid != "true"
    this.hopperTroughTarget.disabled = selectedModel.dataset.pyramid != "true"

    const selectedHousing = this.housingsTarget.querySelector("input:checked")
    if (selectedHousing.dataset.deductHopper == "true") {
      this.hopperPyramidsTarget.disabled = true
      this.hopperTroughTarget.disabled = true
      this.hopperLowBoyTarget.disabled = true
    }

    // housing should not be both checked & disabled
    if (this.hoppersTarget.querySelector("input:checked").disabled)
      this.hopperStandardTarget.checked = true
  }

  updatePreview() {
    let path = this.modelsTarget.querySelector("option:checked").dataset.preview
    if (!this.hasPreviewTarget)
      return
    this.previewTarget.classList.add("animate__fadeOut")
    this.previewTarget.src = path
    this.previewTarget.classList.add("animate__fadeIn")
  }

  selectedModel() {
    this.modelsTarget.querySelector("option:checked")
  }

  updateDischargePoints() {
    let gsModel = this.modelsTarget.querySelector("option:checked")
    const hopperType = this.hoppersTarget.querySelector("input:checked").id
    let attributeToUse
    if (hopperType.includes("pyramid")) {
      attributeToUse = "pyramids"
    } else if (hopperType.includes("trough")) {
      attributeToUse = "troughs"
    } else {
      attributeToUse = "hoppers"
    }
    this.dischargePointsTarget.textContent = gsModel.dataset[attributeToUse]
  }

  filterChange() {
    // classic GS always allows SS cartridges
    if (!this.xfloValue) {
      this.stainlessTarget.disabled = false;
      return
    }

    let checkedFilter = document.querySelector(".xflo-filters input:checked")
    if (checkedFilter.dataset.stainless == "true") {
      this.stainlessTarget.disabled = false
    } else {
      this.stainlessTarget.disabled = true
      this.stainlessTarget.checked = false
    }
  }

}
