import { Controller } from "@hotwired/stimulus"

export default class Inlets extends Controller {
  static targets = ["scib", "arInlet", "dom", "requiresScib", "plate"]
  static values = {
    pharma: { type: Boolean, default: false },
  }

  connect() {
    this.reconcileScib()
  }

  reconcileScib() {
    if (this.pharmaValue) {
      this.scibTarget.checked = true
      return
    }

    this.requiresScibTargets.forEach(element => {
      if (this.scibTarget.checked) {
        element.disabled = false
      } else {
        element.disabled = true
        element.checked = false
      }
    })
  }
}
