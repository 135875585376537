import { Controller } from "@hotwired/stimulus"

export default class OptionsController extends Controller {
  static targets = ["betterOptions"]

  buildBetterOptionIds() {
    let betterIds = []
    document.querySelectorAll(".selected_option").forEach(selectedOption => {
      betterIds.push(selectedOption.dataset.betterOptionId)
    })

    this.betterOptionsTarget.value = betterIds
  }
}
