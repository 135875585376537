import { Controller } from "@hotwired/stimulus"

export default class VisibilityController extends Controller {
  static targets = ["toggler", "togglee", "togglerByNodes"]
  static outlets = ["visibility"]
  static values = { toggleVisibility: Boolean,
    invert: Boolean,
    skipOnLoad: { type: Boolean, default: false },
    skipAnimation: { type: Boolean, default: false },
    europe: { type: Boolean, default: false },
    showStaticOnConnect: { type: Boolean, default: false }
  }

  debug() {
    console.log("debug visibility")
  }

  initialize() {
    if (this.skipOnLoadValue)
      return

    if (this.showStaticOnConnectValue) {
      this.toggleeTargets.forEach(togglee => this.show(togglee))
      return
    }

    this.toggleeTargets.forEach((togglee) => {
      if (!this.skipAnimationValue) {
        togglee.classList.add('animate__animated')

        togglee.addEventListener('animationend', () => {
          if (togglee.classList.contains('animate__fadeOut')) {
            togglee.classList.add('d-none')
          } else { // showing the element
            togglee.classList.remove('d-none')
          }
        })
      }
    })

    this.togglerByNodesTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.visibilityNodesParam).forEach((togglee) => {
        if (!this.skipAnimationValue) {
          togglee.classList.add('animate__animated')
          togglee.addEventListener('animationend', () => {
            if (togglee.classList.contains('animate__fadeOut')) {
              togglee.classList.add('d-none')
            } else { // showing the element
              togglee.classList.remove('d-none')
            }
          })
        }
      })
    })

    this.toggle()
    this.toggleNodes()

    if (this.element.querySelectorAll("[data-action*=disableForMe]:checked").length > 0)
      this.disableForMe()
  }

  toggle() {
    this.toggleVisibility()
  }

  disableForMe() {
    this.toggleeTargets.forEach((togglee) => {
      togglee.disabled = true
      togglee.checked = false
    })
  }

  toggleVisibility() {
    let needToShow
    this.togglerTargets.forEach((toggler) => {
      if (toggler.checked)
        needToShow = true
    })

    if (this.invertValue) {
      needToShow = !needToShow
    }

    this.toggleeTargets.forEach((togglee) => {
      if (needToShow) {
        togglee.classList.remove('animate__fadeOut', 'd-none')
        togglee.classList.add('animate__fadeIn')
      } else {
        togglee.classList.add('animate__fadeOut')
      }
      return
    })
  }

  toggleNodes() {
    this.togglerByNodesTargets.forEach(toggler => {
      document.querySelectorAll(toggler.dataset.visibilityNodesParam).forEach(togglee => {
        let needToShow = toggler.checked
        if (this.invertValue)
          needToShow = !needToShow
        if (needToShow) {
          this.show(togglee)
        } else {
          this.hide(togglee)
        }
      })
    })
  }

  linkToggle(event) {
    event.preventDefault()
    this.toggleeTargets.forEach(togglee => {
      if (togglee.classList.contains("d-none")) {
        this.show(togglee)
        // let texts = togglee.querySelectorAll("input[type=text]")
        // if (texts.length)
        //   togglee.querySelector("input[type=text]").focus()
      } else {
        this.hide(togglee)
      }
    })

    this.visibilityOutlets.forEach(visibility => {
      visibility.linkToggle(new Event("click"))
    })
  }

  linkToggleNodes(event) {
    event.preventDefault()
    let nodes = document.querySelectorAll(event.params.nodes)
    nodes.forEach(togglee => {
      if (!this.skipAnimationValue) {
        togglee.classList.add("animate__animated")

        togglee.addEventListener('animationend', () => {
          if (togglee.classList.contains('animate__fadeOut')) {
            togglee.classList.add('d-none')
          } else { // showing the element
            togglee.classList.remove('d-none')
          }
        })
      }

      if (togglee.classList.contains("d-none")) {
        this.show(togglee)
      } else {
        this.hide(togglee)
      }
    })
  }

  linkShowNodes(event) {
    event.preventDefault()
    let nodes = document.querySelectorAll(event.params.showNodes)
    nodes.forEach(togglee => {
      if (!this.skipAnimationValue) {
        togglee.classList.add("animate__animated")
        togglee.addEventListener('animationend', () => togglee.classList.remove('d-none'))
      }
      if (togglee.classList.contains("d-none"))
        this.show(togglee)
    })
  }

  linkHideNodes(event) {
    event.preventDefault()
    let nodes = document.querySelectorAll(event.params.hideNodes)
    nodes.forEach(togglee => {
      if (!this.skipAnimationValue) {
        togglee.classList.add("animate__animated")
        togglee.addEventListener('animationend', () => togglee.classList.add('d-none'))
      }
      this.hide(togglee)
    })
  }

  show(element) {
    element.classList.remove("animate__fadeOut", "d-none")
    if (!this.skipAnimationValue)
      element.classList.add("animate__fadeIn")
  }

  hide(element) {
    if (this.skipAnimationValue) {
      element.classList.add("d-none")
    } else {
      element.classList.add("animate__fadeOut")
    }
  }
}
