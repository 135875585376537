import { Controller } from "@hotwired/stimulus"

export default class EmoTwoOptionsController extends Controller {
  static targets = ["measurementNotRequired", "electricalController", "d3"]
  static values = {
    vec: Boolean,
    fifty: Boolean
  }

  connect() {
    this.reconcileController()
    console.log(this.electricalControllerTarget.disabled)
  }

  reconcileController() {
    if (!this.vecValue && this.measurementNotRequiredTarget.checked) {
      console.log("disable it")
      this.electricalControllerTarget.disabled = true
      this.electricalControllerTarget.checked = false
      return // always disable EC under these conditions
    }

    if (this.fiftyValue && !this.d3Target.checked) {
      this.electricalControllerTarget.disabled = false
    } else {
      this.electricalControllerTarget.disabled = true
      this.electricalControllerTarget.checked = false
    }
  }
}
