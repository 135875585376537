import { Controller } from "@hotwired/stimulus"

export default class HandteFansController extends Controller {
  static targets = ["integratedItcs", "integratedStarter",
                    "preventItcElement", "preventStarterElement", "customize"]

  connect() {
    this.reconcile()
    this.customize()
  }

  reconcile() {
    if (this.preventStarterElementChecked()) {
      if (this.hasIntegratedStarterTarget) {
        this.integratedStarterTarget.classList.add("d-none")
        this.integratedStarterTarget.querySelectorAll("input[type=checkbox]").forEach(cb => cb.checked = false)
      }
    } else {
      if (this.hasIntegratedStarterTarget)
        this.integratedStarterTarget.classList.remove("d-none")
    }

    if (this.preventItcElementChecked()) {
      this.integratedItcsTarget.classList.add("d-none")
    } else {
      this.integratedItcsTarget.classList.remove("d-none")
    }
  }

  preventStarterElementChecked() {
    return document.querySelectorAll("[data-handte-fans-target*='preventStarterElement']:checked").length > 0
  }

  preventItcElementChecked() {
    return document.querySelectorAll("[data-handte-fans-target*='preventItcElement']:checked").length > 0
  }

  customize() {
    document.querySelectorAll(".customize input[type=number], .customize textarea").forEach(element => {
      if (this.customizeTarget.checked) {
        element.disabled = false
      } else {
        element.disabled = true
      }
    })
  }
}
