import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["descriptionField", "simpleExclusion", "simpleExclusionForm"]

  checkAll(event) {
    event.preventDefault()
    this.simpleExclusionTargets.forEach((cb) => cb.checked = true)
    this.submitForm()
  }

  reset(event) {
    event.preventDefault()
    this.simpleExclusionTargets.forEach((cb) => cb.checked = false)
    this.submitForm()
  }

  submitForm() {
    const simpleExclusionForm = document.querySelector("[data-simple-exclusion-form]")
    Rails.fire(simpleExclusionForm, "submit")
  }
}
