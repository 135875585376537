import { Controller } from "@hotwired/stimulus"

export default class CancelController extends Controller {
  connect() {
    let form = this.element.closest("form")
    let existingControllers = form.dataset.controller || ""
    existingControllers += " dismiss"
    form.dataset.controller = existingControllers

    this.element.dataset.action = "dismiss#dismiss"
  }
}
