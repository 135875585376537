import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class ListController extends Controller {
  static targets = ["list", "listA", "listB"]
  static values = {
    autoSubmitUrl: String,
    submitListSelector: String
  }

  addToList(event) {
    event.preventDefault()
    let newItem = event.target.cloneNode(true)
    newItem.dataset.action = "list#removeFromList"
    newItem.dataset.addedToList = true
    newItem.classList.add("btn-remove-right")
    this.listTarget.append(newItem)
    event.target.remove()
    this.dispatch("addedToList")
    if (this.autoSubmitUrlValue) {
      this.submit()
    }
  }

  removeFromList(event) {
    event.preventDefault()
    event.target.remove()
  }

  swapList(event) {
    event.preventDefault()
    let currentList = event.target.closest("[data-list-target*='list']")
    let targetList = (currentList == this.listATarget) ? this.listBTarget : this.listATarget
    targetList.append(event.target)
  }

  submit() {
    if (!this.autoSubmitUrlValue || !this.submitListSelectorValue)
      return

    let data = []
    let method = "patch"
    let list = document.querySelector(this.submitListSelectorValue)
    list.querySelectorAll("[data-list-item-id]").forEach(itm => data.push(itm.dataset.listItemId))
    const request = new FetchRequest(method, this.autoSubmitUrlValue, { query: { items: data }})
    request.perform()
  }

}
