import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["package", "msgNoneYet", "msgChangeQuantity"]

  removePart() {
    let remainingPartsInPackage = document.querySelectorAll(".part").length - 1

    if (remainingPartsInPackage < 1) {
      this.msgNoneYetTarget.classList.remove("d-none")
      this.msgChangeQuantityTarget.classList.add("d-none")
    }
  }
}
