// for submitting items based on checkbox status

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["multipleList", "hiddenIds", "item"]

  setupMultipleIds(event) {
    let theIds = []
    let stopSubmit = false
    if (event.params.confirm) {
      if (!confirm(event.params.message)) {
        stopSubmit = true
      }
    }

    if (stopSubmit) {
      this.dispatch("cancelled")
      event.preventDefault()
      event.stopPropagation()
    } else {
      this.multipleListTarget.querySelectorAll("input[type=checkbox]:checked").forEach(checkbox => {
        theIds.push(checkbox.value)
      })
    }

    this.hiddenIdsTargets.forEach(hiddenField => {
      hiddenField.value = theIds.join("||")
    })
  }

  setupFromList(_event) {
    let theIds = []

    this.itemTargets.forEach(tr => theIds.push(tr.dataset.multiplesId))

    this.hiddenIdsTargets.forEach(hiddenField => {
      hiddenField.value = theIds.join("||")
    })
  }

  removeMultipleIds() {
    let checkboxes = this.multipleListTarget.querySelectorAll("input[type=checkbox]:checked")
    checkboxes.forEach(checkbox => checkbox.closest("[data-multiples-target=item]").remove())
    this.dispatch("removeComplete")
  }

  removeAllIds(event) {
    let stopSubmit = false
    if (event.params.confirm) {
      if (!confirm(event.params.message)) {
        stopSubmit = true
      }
    }

    if (stopSubmit) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      document.querySelectorAll("tbody input[type='checkbox']").forEach((checkbox) => {
        checkbox.closest("tr").remove()
      })
    }

    this.dispatch("removeComplete")
  }
}
