import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dual', 'drum', 'quad_pulse_cartridge_fire_retardant', 'quad_pulse_cartridge_aluminized'];

  connect() {
    this.modelChange()
  }

  modelChange() {
    let checkedModel = document.querySelector('.models input:checked');

    if (checkedModel.dataset.dualValve == 'true') {
      this.dualTarget.disabled = false;
    } else {
      this.dualTarget.disabled = true;
      this.dualTarget.checked = false;
    }

    if (checkedModel.dataset.fireCartridge == 'true') {
      this.quad_pulse_cartridge_fire_retardantTarget.disabled = false;
    } else {
      this.quad_pulse_cartridge_fire_retardantTarget.disabled = true;
      this.quad_pulse_cartridge_fire_retardantTarget.checked = false;
      if (document.querySelectorAll('fieldset[data-cartridges] input:checked').length < 1) {
        this.quad_pulse_cartridge_aluminizedTarget.checked = true
      }
    }
  }
}
