import { Controller } from "@hotwired/stimulus"

export default class ArchiverController extends Controller {
  static targets = ["toggler"]
  static values = { archived: Boolean,
                    unarchiveOnly: Boolean }

  toggleArchivedStatus() {
    if (this.archivedValue) {
      if (this.unarchiveOnlyValue) {
        this.togglerTarget.classList.add('collapse')
        return
      }

      this.archivedValue = false;
      this.togglerTarget.textContent = "Archive"
    } else {
      this.archivedValue = true;
      this.togglerTarget.textContent = "Unarchive"
    }
  }
}
