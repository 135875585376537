import { Controller } from "@hotwired/stimulus"

export default class HideSpecificController extends Controller {
  static targets = ["hider"]
  static values = {
    anyChecked: { type: Boolean, default: false },
    nodes: { type: String, default: "" }
  }

  connect() {
    this.hiderTargets.forEach(hider => {
      hider.dataset.action += " hide-specific#hide"
    })

    this.hide()
  }

  hide(event) {
    if (event && event.params.onlySelector) {
      document.querySelectorAll(event.params.onlySelector).forEach(el => el.classList.add("d-none"))
      return
    }

    if (this.anyCheckedValue) {
      let anyChecked = false
      this.hiderTargets.forEach(hider => {
        if (hider.checked)
          anyChecked = true
      })

      if (anyChecked && this.nodesValue != "")
        document.querySelectorAll(this.nodesValue).forEach(element => element.classList.add("d-none"))

      return
    }

    document.querySelectorAll(this.nodesValue).forEach(element => {
      if (this.hiderTarget.checked) {
        element.classList.add("d-none")
      }
    })
  }
}
