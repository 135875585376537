import { Controller } from "@hotwired/stimulus"

export default class LinkDisableController extends Controller {
  static values = {
    nodes: String
  }

  disableNodes(event) {
    event.preventDefault()
    let disablees = document.querySelectorAll(this.nodesValue)
    disablees.forEach(disablee => disablee.disabled = true)
  }
}
