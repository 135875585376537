import { Controller } from "@hotwired/stimulus"

export default class SimpleToggle extends Controller {
  connect() {
    const selector = "[data-action*='simple-toggle#toggleNode'], [data-action*='simple-toggle#toggleNodes']"
    this.element.querySelectorAll(selector).forEach(node => {
      const querySelector = node.dataset.simpleToggleNodeParam || node.dataset.simpleToggleNodesParam
      const animatedElements = document.querySelectorAll(querySelector)
      animatedElements.forEach(element => {
        element.classList.add("animate__animated", "animate__faster")

        element.addEventListener("animationend", () => {
          if (element.classList.contains("animate__fadeOut")) {
            element.classList.add("d-none")
          }

          if (!node.dataset.simpleToggleSkipDispatchParam && !element.classList.contains("d-none"))
            this.dispatch("toggleFinished", { detail: { element: element } })
        })
      })

      const hideElements = document.querySelectorAll(node.dataset.simpleToggleHideNodesParam)
      hideElements.forEach(element => {
        element.classList.add("animate__animated", "animate__faster")

        element.addEventListener("animationend", () => {
          if (element.classList.contains("animate__fadeOut")) {
            element.classList.add("d-none")
          }
        })
      })

      // open on page load
      if (node.checked) {
        animatedElements.forEach(element => this.toggleSingle(element))
        hideElements.forEach(element => this.hideSingle(element))
      }
    })
  }

  toggleNodes(event) {
    event.preventDefault()
    let elements

    if (event.detail && event.detail.elementsToToggle) {
      elements = event.detail.elementsToToggle
    } else {
      elements = document.querySelectorAll(event.params.nodes)
    }
    elements.forEach(element => this.toggleSingle(element))

    let hiddenElements = document.querySelectorAll(event.params.hideNodes)
    if (event.target.checked) {
      hiddenElements.forEach(element => this.hideSingle(element))
    } else {
      hiddenElements.forEach(element => this.toggleSingle(element))
    }
  }

  hideNodes(event) {
    event.preventDefault()
    let elements = document.querySelectorAll(event.params.hideNodes)
    elements.forEach(element => this.hideSingle(element))
  }

  toggleNode(event) {
    event.preventDefault()
    if (event.params.onlyOne && event.params.nodes)
      document.querySelectorAll(event.params.nodes).forEach(element => element.classList.add("d-none"))
    let element = document.querySelector(event.params.node)
    this.toggleSingle(element)
  }

  toggleSingle(element) {
    if (element.classList.contains("d-none")) {
      element.classList.remove("animate__fadeOut", "d-none")
      element.classList.add("animate__fadeIn")
    } else {
      element.classList.add("animate__fadeOut")
    }
  }

  hideSingle(element) {
    if (!element.classList.contains("d-none"))
      element.classList.add("animate__fadeOut")
  }

  showNodes(event) {
    event.preventDefault()
    let elements

    if (event.detail.elementsToToggle) {
      elements = event.detail.elementsToToggle
    } else {
      elements = document.querySelectorAll(event.params.nodes)
    }
    elements.forEach(element => this.showSingle(element))
  }

  showSingle(element) {
    element.classList.remove("animate__fadeOut", "d-none")
    element.classList.add("animate__fadeIn")
  }
}
