import { Controller } from "@hotwired/stimulus"

export default class ControlsController extends Controller {
  toggleControlsIf(event) {
    let controlsToEnable = document.querySelectorAll(event.params.toggleSelector)
    let evl = eval
    let ifCondition = evl(event.params.ifCondition)
    controlsToEnable.forEach(control => control.disabled = !ifCondition)

  }
}
