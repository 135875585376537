let reconcileOne = function(rdio) {
  let main4 = document.querySelector('.oeo_main_4')
  let main3 = document.querySelector('.oeo_main_3')
  let fourAllowed = rdio.dataset.four == 'true'

  if (fourAllowed) {
    main4.disabled = false
  } else {
    main4.disabled = true
    main3.checked = true
  }

  let reinforcedAllowed = rdio.dataset.reinforced == 'true';
  let reinforcedFan = document.querySelector('#expert_one_reinforced_fan');
  if (reinforcedAllowed) {
    reinforcedFan.disabled = false;
  } else {
    reinforcedFan.disabled = true;
    reinforcedFan.checked = false;
  }
}

document.addEventListener('turbo:load', function(){
  let radios = document.querySelectorAll('body.expert_ones .models input[type=radio]');

  if (radios.length) {
    let checkd = document.querySelector('.models input[type=radio]:checked');

    radios.forEach((rdio) => {
      rdio.addEventListener('change', () => {
        reconcileOne(document.querySelector('.models input[type=radio]:checked'))
      })
    })

    checkd.dispatchEvent(new Event('change'));
  }
})
