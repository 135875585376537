import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"];

  static values = {
    initialPaddingClass: { type: String, default: "my-3" },
    skipOnlyOne: Boolean,
    positionAbsolute: { type: Boolean, default: false },
    wrapWith: String,
    debug: Boolean
  }

  displayResults(event) {
    let xhr = event.detail[2].response
    document.querySelectorAll("[data-copy-projects]").forEach(element => element.remove())

    if (this.positionAbsoluteValue) {
      this.addPositionedContent(xhr, event.target)
    } else {
      this.addContent(xhr)
    }
  }

  displayAndPosition(event) {
    let xhr = event.detail[2].response
    this.addPositionedContent(xhr, event.target)
  }

  displayAndPositionBottom(event) {
    let xhr = event.detail[2].response
    this.addBottomPositionedContent(xhr, event.target)
  }

  displayAndAppendTo(event) {
    if (this.debugValue)
      console.log("fire appendTo")
    let xhr = event.detail[2].response
    let element = document.querySelector(event.params.appendToElement) || this.element
    if (xhr) {
      this.dispatch("begin")
      this.addContent(xhr, element, "beforeend")
    }
  }

  displayAndAppendToOnce(event) {
    if (this.debugValue)
      console.log("fire appendTo")
    let xhr = event.detail[2].response
    let element = document.querySelector(event.params.node) || this.element
    if (xhr) {
      this.dispatch("begin")
      this.addContent(xhr, element, "beforeend")
      let action = event.target.dataset.action.replace(`${event.type}->display#displayAndAppendToOnce`, "")
      event.target.dataset.action = action
    }
  }

  displayAndPrependTo(event) {
    let xhr = event.detail[2].response
    let element = document.querySelector(event.params.prependTo)
    if (xhr && element) {
      this.dispatch("begin")
      this.addContent(xhr, element, "afterbegin")
    }
  }

  displayAndReplace(event) {
    let xhr = event.detail[2].response
    let element = document.querySelector(event.params.replaceElement) || this.element
    if (xhr && element) {
      this.addContent(xhr, element, "afterend")
      element.remove()
    }
  }

  replaceInner(event) {
    let xhr = event.detail[2].response
    let container = document.querySelector(event.params.replacee)
    container.innerHTML = xhr
  }

  displayOrToggle(event) {
    let content = this.element.nextElementSibling
    if (content && content.dataset.displayToggle == "true") {
      content.remove()
      this.dispatch("finished")
      return
    }

    let xhr = event.detail[2].response
    this.addContent(xhr)
  }

  displayOrToggleNodes(event) {
    let xhr = event.detail[2].response
    let nodesToToggle = document.querySelectorAll(event.params.nodes)

    nodesToToggle.forEach(node => {
      if (node.innerHTML != "") {
        node.innerHTML = ""
      } else {
        this.addContent(xhr, node, "beforeend")
      }
    })
  }

  displayAndToggle(event) {
    let xhr = event.detail[2].response
    this.addContent(xhr)
  }

  addContent(xhr, element = this.element, position = "afterend") {
    if (!this.skipOnlyOneValue) {
      document.querySelectorAll("[data-display-only-one]").forEach(element => element.remove())
    }

    if (this.wrapWithValue)
      xhr = this.wrapWithValue.replace("::inner::", xhr)
    element.insertAdjacentHTML(position, xhr)
    if (element.nextElementSibling && position == "afterend")
      element.nextElementSibling.classList.add(this.initialPaddingClassValue)
    this.dispatch("finished")
  }

  addPositionedContent(xhr, target) {
    document.querySelectorAll("[data-display-only-one]").forEach(element => element.remove())
    document.body.insertAdjacentHTML("beforeend", xhr)
    let form = document.body.lastChild
    if (this.debugValue)
      console.log(form)
    let rect = target.getBoundingClientRect()
    let formRect = form.getBoundingClientRect()
    form.style.top = `${rect.top + window.scrollY - (formRect.height / 2)}px`
    form.style.left = `${rect.left - 15}px`
    form.classList.add("shadow")
    form.style.position = "absolute"
    this.dispatch("finished")
  }

  addBottomPositionedContent(xhr, target) {
    document.querySelectorAll("[data-display-only-one]").forEach(element => element.remove())
    document.body.insertAdjacentHTML("beforeend", xhr)
    let form = document.body.lastChild
    let rect = target.getBoundingClientRect()
    form.style.top = `${rect.top + rect.height + window.scrollY + 10}px`
    form.style.left = `${rect.left}px`
    form.classList.add("positioned-form")
    form.classList.add("shadow")
    form.style.position = "absolute"
    this.dispatch("finished")
  }
}
