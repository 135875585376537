import { Controller } from "@hotwired/stimulus"

export default class ClipboardController extends Controller {
  static values = {
    inform: { type: Boolean, default: false }
  }
  static targets = ["source", "informMessage"]

  connect() {
    if (this.informValue) {
      let informHTML = '<div class="d-none small text-muted" data-clipboard-target="informMessage">Copied!</div>'
      this.element.insertAdjacentHTML("beforeend", informHTML)
    }
  }

  copy(event) {
    event.preventDefault()
    let hiddenTextArea = document.createElement("textarea")

    hiddenTextArea.value = this.sourceTarget.value
    document.body.append(hiddenTextArea)
    hiddenTextArea.focus()
    hiddenTextArea.select()
    document.execCommand("copy")
    hiddenTextArea.remove()

    this.dispatch("copyFinished", { detail: { element: event.target } })
  }

  copyQuick(event) {
    event.preventDefault()
    navigator.clipboard.writeText(event.params.text)
    if (this.hasInformMessageTarget) {
      this.informMessageTarget.classList.add("d-inline-block")
      this.informMessageTarget.classList.remove("d-none")
    }
  }
}
