import { Controller } from "@hotwired/stimulus"

export default class LinkEnableController extends Controller {
  static values = {
    nodes: String
  }

  enableNodes() {
    let elements = document.querySelectorAll(this.nodesValue)
    elements.forEach(element => element.disabled = false)
  }
}
