import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["options", "construction", "onlyFloor", "noFloor",
                    "roofMount", "floorMount", "starter", "atex", "damper"]

  static values = {
    projectId: String,
    collectorId: String
  }

  connect() {
    this.modelChange()
  }

  chosenModel() {
    return document.querySelector("#model input[type=radio]:checked")
  }

  modelChange() {
    const controlsSelector = "[data-eu-fan-target=options] input[type=checkbox], " +
                             "#starters input[type=radio], " +
                             '[data-target="eu-fan.construction"] input'
    const controls = document.querySelectorAll(controlsSelector)
    const chosenModel = this.chosenModel()
    if (!chosenModel)
      return

    if (chosenModel.dataset.notRequired == 'false') {
      controls.forEach((el) => {
        el.disabled = false
      })
    } else { // fan not required
      controls.forEach((el) => {
        el.checked = false
        el.disabled = true
      })
      return
    }

    // reconcile construction types
    if (chosenModel.dataset.constructionRoof == 'true') {
      this.roofMountTargets.forEach(el => el.disabled = false)
    } else {
      this.roofMountTargets.forEach((el) => {
        el.disabled = true
        el.checked = false
      })
    }

    // all atex n/a for now....
    this.atexTargets.forEach(element => {
      let noAtex = chosenModel.dataset.atexAvailable == "false"
      element.disabled = noAtex

      if (noAtex)
        element.checked = false
    })

    // reconcile dampers
    let noDamper = chosenModel.dataset.damper == "false"
    this.damperTarget.disabled = noDamper
    if (noDamper)
      this.damperTarget.checked = false

    // reconcile starters available
    if (chosenModel.dataset.starter == "true") {
      this.starterTarget.disabled = false
    } else {
      this.starterTarget.disabled = true
      this.starterTarget.checked = false
    }

    this.constructionChange()

    this.dispatch("modelChange", { detail: {
      elementToChange: this.starterTarget.closest("div"),
      newCode: chosenModel.dataset.euIntegratedStarterCode,
      collectorId: this.collectorIdValue,
      projectId: this.projectIdValue } })
  }

  constructionChange() {
    const chosenConstruction = this.constructionTarget.querySelector("input[type=radio]:checked")

    if (!chosenConstruction || !this.chosenModel())
      return

    if (chosenConstruction.dataset.floor == "true") {
      this.onlyFloorTargets.forEach(el => el.disabled = false)
      this.noFloorTargets.forEach((el) => {
        el.disabled = true
        el.checked = false
      })
      return
    } else { // roof mount
      this.onlyFloorTargets.forEach((el) => {
        el.disabled = true
        el.checked = false
      })
      this.noFloorTargets.forEach(el => el.disabled = false)
    }
  }
}
