import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['mainFilters', 'bottoms'];

  initialize() {
    this.reconcileHoppers();
  }

  reconcileHoppers() {
    const hoppers = document.querySelectorAll('[data-hopper=true]');

    // no hoppers available with 3-stage main filter
    if (this.threeStageChosen()) {
      hoppers.forEach((el) => {
        el.checked = false;
        el.disabled = true;
      })
    } else { // using 2-staging
      hoppers.forEach((el) => {
        el.disabled = false;
      })

      this.configureHoppers();
    }
  }

  configureHoppers() {
    return 

    // enable only hoppers that match main filter orientation
    const mainFilter = this.mainFiltersTarget.querySelector('input[type=radio]:checked');
    const sideToEnable = mainFilter.dataset.mainOrientation;
    const hingedChosen = mainFilter.dataset.hinged == 'true';

    this.bottomsTarget.querySelectorAll('input[data-hopper=true][type=radio]').forEach((el) => {
      if (el.dataset.orientation == sideToEnable) {
        el.disabled = false;
      } else {
        el.disabled = true;
      }
    })

    if (hingedChosen) {
      this.bottomsTarget.querySelectorAll('input[data-hopper=true][data-hinged=true]').forEach((el) => el.disabled = false);
      this.bottomsTarget.querySelectorAll('input[data-hopper=true][data-hinged=false]').forEach((el) => {
        el.disabled = true;
      });
    } else {
      this.bottomsTarget.querySelectorAll('input[data-hinged=true]').forEach((el) => {
        el.disabled = true;
      });
    }

    // don't leave any checked & disabled items
    this.bottomsTarget.querySelectorAll('input:disabled:checked').forEach((el) => el.checked = false);
  }

  threeStageChosen() {
    return document.querySelectorAll('[data-three-stage=true]:checked').length > 0;
  }

  voltageFifty() {
    return document.querySelectorAll('input[data-voltage-fifty=true]:checked').length > 0
  }
}
