// Entry point for the build script in your package.json
import Sortable from "sortablejs"
import flatpickr from "flatpickr"
window.Sortable = Sortable
import "animate.css"
import "./controllers"
import Rails from "@rails/ujs"
Rails.start()

import * as bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/fontawesome"
import "@fortawesome/fontawesome-free/js/solid"
import "./main"
import "@hotwired/turbo-rails"
