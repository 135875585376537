import { Controller } from "@hotwired/stimulus"

export default class SearchController extends Controller {
  static targets = ["clear"]

  finish() {
    this.clearTargets.forEach(itm => itm.remove())
  }

  setProperties(event) {
    this.element.querySelectorAll("a.search-result").forEach(link => {
      let values = event.params.resultValues.split("||")
      event.params.resultProperties.split("||").forEach((property, ndx) => {
        let value = values[ndx].replace("placeholder", link.id)
        link.setAttribute(property, value)
      })
    })
  }
}
