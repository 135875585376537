import { Controller } from "@hotwired/stimulus"

export default class XfloInlets extends Controller {
  static targets = ["sparkArrestor", "notWithSpark", "saib", "plate", "arInlet",
    "arInletQuantity", "dom"]

  connect() {
    this.sparkArrestorToggle()
    this.saibToggle()
  }

  sparkArrestorToggle() {
    if (this.sparkArrestorTarget.checked) {
      this.notWithSparkTargets.forEach(function(ckb) {
        ckb.checked = false
        ckb.disabled = true
      })
    } else {
      this.notWithSparkTargets.forEach(function(ckb) {
        ckb.disabled = false
      })
    }

    this.arToggle()
  }

  saibToggle() {
    if (this.saibTarget.checked) {
      this.arInletTarget.disabled = false
      this.domTarget.disabled = false
    } else {
      this.arInletTarget.checked = false
      this.arInletTarget.disabled = true
      this.domTarget.checked = false
      this.domTarget.disabled = true
    }
    this.arToggle()
  }

  arToggle() {
    this.arInletQuantityTarget.disabled = !this.arInletTarget.checked
  }
}
