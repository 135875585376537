import { Controller } from "@hotwired/stimulus"

export default class TableSorterController extends Controller {
  static values = {
    setupOnLoad: { type: Boolean, default: true }
  }

  connect() {
    if (this.setupOnLoadValue)
      this.element.querySelectorAll("table").forEach(table => this.setupTable(table))
  }

  setupTable(table) {
    const comparer = (idx, asc) => (a, b) => ((v1, v2) => 
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(this.cellValue(asc ? a : b, idx), this.cellValue(asc ? b : a, idx));

    const tbody = table.querySelector("tbody")
    table.querySelectorAll("th").forEach(th => th.addEventListener("click", () => {
      let trArray = Array.from(tbody.querySelectorAll("tr:nth-child(n+1)"))
      let thArray = Array.from(th.parentNode.children)
      trArray.sort(comparer(thArray.indexOf(th), this.asc = !this.asc))
        .forEach(tr => {
          tbody.appendChild(tr)
        })
    }))
  }

  cellValue(tr, idx) {
    return tr.children[idx].innerText || tr.children[idx].textContent
  }

  setupTableAgain(event) {
    let table = document.querySelector(event.params.node)
    this.setupTable(table)
  }
}
