import { Controller } from "@hotwired/stimulus"

export default class OrdersController extends Controller {
  static targets = ["warningDrawingsOneRequired", "warning", "warningDrawingsNoOther", "other",
    "submittalNotes", "warningDrawingsSubmittalNotesRequired", "submittal",
    "nonApprovalWith", "nonApprovalWithout", "approval", "threed", "otherDescription", "shippingInstructions",
    "billEmail", "newCustomerAcknowledgementEmail", "newCustomer", "newCustomerInvoiceEmail",
    "newCustomerFax", "billFax"]

  static values = {
    europe: { type: Boolean, default: false },
    submittalDrawings: Number,
    approvalDrawings: Number,
    nonApprovalWithDrawings: Number,
    nonApprovalWithoutDrawings: Number,
    threedDrawings: Number,
    otherDrawings: Number
  }

  connect() {
    this.countDrawings()
    let selectedDrawing = document.querySelector("#drawings input[type=number]:not([value='0']):not([data-orders-drawing-type-param=step])")
    if (selectedDrawing)
      selectedDrawing.dispatchEvent(new CustomEvent("input"))
  }

  reconcileDrawingTypes(event) {
    let drawingType = event.params.drawingType
    let disableThese = document.querySelectorAll(`#drawings input[type=number]:not([data-orders-drawing-type-param='${drawingType}']):not([data-orders-drawing-type-param=step])`)
    let enableThese = document.querySelectorAll(`#drawings input[type=number][data-orders-drawing-type-param='${drawingType}']`)

    let drawingTypeCount = 0
    enableThese.forEach(nput => { drawingTypeCount = drawingTypeCount += parseInt(nput.value) })

    if (drawingTypeCount == 0) { // using zero for this drawing type, enable all types
      enableThese = document.querySelectorAll("#drawings input[type=number]")
    } else {
      disableThese.forEach(nput => {
        nput.disabled = true
        nput.value = 0
      })
    }

    enableThese.forEach(nput => nput.disabled = false)
  }

  countDrawings() {
    let count = 0
    this.submittalTargets.forEach(submittal => {
      if (submittal.value > 0)
        count = count + parseInt(submittal.value)
    })
    this.submittalDrawingsValue = count

    count = 0
    this.approvalTargets.forEach(approval => {
      if (approval.value > 0)
        count = count + parseInt(approval.value)
    })
    this.approvalDrawingsValue = count

    count = 0
    this.otherTargets.forEach(other => {
      if (other.value > 0)
        count = count + parseInt(other.value)
    })
    this.otherDrawingsValue = count

    count = 0
    this.nonApprovalWithTargets.forEach(nonApprovalWith => {
      if (nonApprovalWith.value > 0)
        count = count + parseInt(nonApprovalWith.value)
    })
    this.nonApprovalWithDrawingsValue = count

    count = 0
    this.nonApprovalWithoutTargets.forEach(nonApprovalWithout => {
      if (nonApprovalWithout.value > 0)
        count = count + parseInt(nonApprovalWithout.value)
    })
    this.nonApprovalWithoutDrawingsValue = count

    count = 0
    this.threedTargets.forEach(threed => {
      if (threed.value > 0)
        count = count + parseInt(threed.value)
    })
    this.threedDrawingsValue = count
  }

  submittalDrawingsValueChanged() {
    this.drawingChanged()
  }

  otherDrawingsValueChanged() {
    this.drawingChanged()
  }

  drawingChanged() {
    if (this.submittalDrawingsValue == 0) {
      this.submittalNotesTarget.disabled = true
    } else {
      this.submittalNotesTarget.disabled = false
    }
  }

  checkValid() {
    let result = true

    if (this.noDrawingsSelected()) {
      this.warningDrawingsOneRequiredTarget.classList.remove("d-none")
      this.dispatch("scroll", { detail: { element: this.warningDrawingsOneRequiredTarget } })
      result = false
    }

    if (this.noOther()) {
      this.warningDrawingsNoOtherTarget.classList.remove("d-none")
      this.dispatch("scroll", { detail: { element: this.warningDrawingsNoOtherTarget } })
      result = false
    }

    if (this.submittalDrawingsValue > 0 && this.submittalNotesTarget.value.trim() == "") {
      this.warningDrawingsSubmittalNotesRequiredTarget.classList.remove("d-none")
      this.dispatch("scroll", { detail: { element: this.warningDrawingsSubmittalNotesRequiredTarget } })
      result = false
    }

    return result
  }

  noOther() {
    if (this.otherDescriptionTarget.value.trim() != "")
      return false

    return this.otherDrawingsValue > 0
  }

  noDrawingsSelected() {
    if (this.europeValue)
      return false

    let totalDrawings = 0
    document.querySelectorAll("#drawings input[type=number]:not([class*=threed])").forEach(nput => {
      totalDrawings += nput.value
    })
    return totalDrawings == 0
  }

  clearWarnings(event) {
    if (event.params.specificWarnings) {
      let selector = `[data-orders-target*=${event.params.specificWarnings}]`
      document.querySelectorAll(selector).forEach(warning => warning.classList.add("d-none"))
      return
    }

    this.warningTargets.forEach(warning => warning.classList.add("d-none"))
  }

  reconcileManager() {
    document.querySelector('#order_approval_regional_manager').value = this.approvalDrawingsValue > 0 ? "1" : "0"
    document.querySelector('#order_submittal_regional_manager').value = this.submittalDrawingsValue > 0 ? "1" : "0"
    document.querySelector('#order_non_approval_without_drawing_regional_manager').value = this.nonApprovalWithoutDrawingsValue > 0 ? "1" : "0"
    document.querySelector('#order_non_approval_with_drawing_regional_manager').value = this.nonApprovalWithDrawingsValue > 0 ? "1" : "0"
    document.querySelector('#order_threed_regional_manager').value = this.threedDrawingsValue > 0 ? "1" : "0"
  }

  newCustomer() {
    if (this.newCustomerTarget.checked) {
      if (!this.newCustomerAcknowledgementEmailTarget.value)
        this.newCustomerAcknowledgementEmailTarget.value = this.billEmailTarget.value

      if (!this.newCustomerInvoiceEmailTarget.value)
        this.newCustomerInvoiceEmailTarget.value = this.billEmailTarget.value

      if (!this.newCustomerFaxTarget.value)
        this.newCustomerFaxTarget.value = this.billFaxTarget.value
    }
  }

  linkContact(event) {
    let fs = event.target.closest("fieldset")
    let context = event.params.context
    let source = event.params.source
    if (event.target.checked) {
      ["company", "contact", "address", "city", "zip", "phone", "email"].forEach(field => {
        fs.querySelector(`#order_${context}_${field}`).value = document.querySelector(`#order_${source}_${field}`).value
      })
    }
  }

  linkContactBack(event) {
    let fs = event.target.closest("fieldset")
    let context = event.params.context
    let source = event.params.backSource
    if (!event.target.checked) {
      ["company", "contact", "address", "city", "zip", "phone", "email"].forEach(field => {
        fs.querySelector(`#order_${context}_${field}`).value = document.querySelector(`#order_${source}_${field}`).value
      })
    }
  }

  linkCustomer(event) {
    let data = JSON.parse(event.detail[2].response)
    console.log(data)
    let fieldset = event.target.closest("fieldset")
    let fields = ["company", "contact", "address", "address_2", "zip", "phone", "email"]
    fields.forEach(field => fieldset.querySelector(`input[id*=${field}]`).value = data[field])
    fieldset.querySelector(`input[id*=city]`).value = `${data["city"]}, ${data["state"]}`
    event.target.closest("[data-visibility-target=togglee]").classList.add("d-none")
  }
}
