import { Controller } from "@hotwired/stimulus"

export default class MutuallyExclusiveController extends Controller {
  static values = {
    debug: { type: Boolean, default: false }
  }

  connect() {
    this.element.querySelectorAll("input[type=checkbox]").forEach(cb => {

      // must allow time for mutually-exclusive checkboxes to resolve before form submit
      if (cb.dataset.action && cb.dataset.action != "" && cb.dataset.action.includes("wizard#submitFormByControl")) {
        cb.dataset.action = cb.dataset.action.replace("submitFormByControl", "submitFormByTimedControl")
      }

      if (cb.dataset.action == "") {
        cb.dataset.action = "mutually-exclusive#reconcile"
      } else {
        cb.dataset.action += " mutually-exclusive#reconcile"
      }
    })
  }

  reconcile(event) {
    let checked = event.target
    if (!checked.checked)
      return

    this.element.querySelectorAll("input[type=checkbox]").forEach(cb => {
      if (cb != checked)
        cb.checked = false
    })
  }
}
