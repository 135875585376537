import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-group"
export default class extends Controller {
  static targets = ["togglee", "usabilityGroup", "ensuredGroup", "ensuredNode"]
  static outlets = ["usability"]
  static values = {
    ensureChecked: { type: Boolean, default: false },
    nodes: { type: String, default: "" },
    ensuredNodes: { type: String, default: "" },
    toggleOnConnect: { type: Boolean, default: true },
    debug: { type: Boolean, default: false }
  }

  connect() {
    if (this.hasUsabilityGroupTarget) {
      this.usabilityGroupTarget.querySelectorAll("input[type=radio]").forEach(radio => {
        radio.dataset.action += " radio-group#toggleUsability"
      })

      if (this.toggleOnConnectValue) {
        // this.toggleUsability()
        let selectedControl = this.usabilityGroupTarget.querySelector("input[type=radio]:checked")
        selectedControl.dispatchEvent(new CustomEvent("input"))
      }
    }
  }

  toggleUsability(event) {
    if (this.debugValue) console.log("toggleUsability start")
    if (event && !event.target.checked)
      return

    if (this.debugValue) console.log(`still going with ${event.target.id}`)
    let selectedControl = this.usabilityGroupTarget.querySelector("input[type=radio]:checked")
    let shouldDisable = selectedControl && selectedControl.dataset.radioGroupDisablesParam == "true"

    if (this.debugValue) {
      if (event)
        console.log(`event.target: ${event.target.id}`)
      console.log(`selectedControl: ${selectedControl.id}`)
      console.log(`shouldDisable: ${shouldDisable}`)
      console.log("-----")
    }

    let targets
    if (this.nodesValue === "") {
      targets = this.toggleeTargets
    } else {
      targets = document.querySelectorAll(this.nodesValue)
    }

    targets.forEach(togglee => {
      if (shouldDisable) {
        if (this.debugValue) console.log(`disabling: ${togglee.id}`)
        togglee.disabled = true
        if (!this.ensureCheckedValue) togglee.checked = false
        // this.usabilityOutlets.forEach(outlet => outlet.toggleNodes())
      } else {
        togglee.disabled = false
      }
    })
    if (this.debugValue) alert("targets are toggled")

    // // if (this.ensureCheckedValue && shouldDisable) {
    // if (this.ensureCheckedValue) {
    //   let groupToCheck
    //   let noEnsuredChecked
    //   if (this.ensuredNodesValue === "")  {
    //     groupToCheck = this.ensuredGroupTarget
    //   } else {
    //     groupToCheck = document.querySelector(this.ensuredNodesValue)
    //   }
    //   if (this.debugValue) {
    //     console.log("groupToCheck: ")
    //     console.log(groupToCheck)
    //   }
    //   noEnsuredChecked = groupToCheck.querySelectorAll("input[type=radio]:checked").length < 1
    //   if (noEnsuredChecked)
    //     groupToCheck.querySelector("input:not([disabled])").checked = true
    // }

    if (this.debugValue) {
      console.log(this.element)
      console.log('toggleUsability is done')
      alert("toggleUsability end")
    }
  }
}
