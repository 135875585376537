import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="uncheck-disable"
export default class extends Controller {
  static targets = ["disabler"]
  static values = {
    nodes: String,
    skipFireOnLoad: { type: Boolean, default: false },
    debug: Boolean
  }

  disablerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "uncheck-disable#disableNodes"
    } else {
      target.dataset.action += " uncheck-disable#disableNodes"
    }
  }

  connect() {
    if (this.debugValue)
      console.log("connect uncheck-disable")

    console.log("1")
    if (this.skipFireOnLoadValue)
      return

    console.log("2")

    setTimeout(() => {
      this.disablerTargets.forEach(disabler => {
        disabler.dispatchEvent(new Event("input"))
      })
    }, 333)
  }

  disableNodes(event) {
    if (this.debugValue)
      console.log("check-disable->disableNodes")

    document.querySelectorAll(this.nodesValue).forEach(node => {
      if (!event.target.checked) {
        node.checked = false
        node.disabled = true
      }
    })
  }
}
