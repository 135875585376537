import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["itemList", "item", "itemForm"]
  static values = {
    modelName: { type: String, default: "" },
    onlyOne: { type: Boolean, default: false },
    skipErrorNotificationFields: { type: Array, default: [] },
    insertPosition: { type: String, default: "afterbegin" },
    topLevelForm: { type: Boolean, default: false }
  }

  itemTargetConnected(target) {
    if (target.dataset.controller == undefined) {
      target.dataset.controller = "dismiss"
    } else {
      if (!target.dataset.controller.includes("dismiss"))
        target.dataset.controller += " dismiss"
    }

    target.querySelectorAll("a").forEach(link => {
      if (link.innerText.match(/Edit/i) || link.href.match(/edit/))
        link.dataset.action = "ajax:success->crud#edit"

      if (link.dataset.method == "delete") {
        link.dataset.action = "ajax:success->dismiss#dismiss"
      }
    })
  }

  itemFormTargetConnected(target) {
    target.dataset.displayOnlyOne = "true"

    target.querySelectorAll("a.btn").forEach(button => {
      if (button.innerText.match(/Cancel/i))
        button.dataset.controller = "cancel"
    })

    if (this.formAction == "edit") {
      target.dataset.action = "ajax:success->crud#update ajax:error->crud#error"
    } else {
      target.dataset.action = "ajax:success->crud#create ajax:error->crud#error"
    }
  }

  itemFormTargetDisconnected(_target) {
    this.formAction = ""
  }

  connect() {
    // setup new button
    if (!this.element.querySelector(".option-group"))
      return

    this.element.querySelector(".option-group").dataset.controller = "display"
    this.element.querySelector(".option-group").querySelectorAll("a").forEach(link => {
      if (link.innerText.match(/add|new/i)) {
        link.dataset.action = "ajax:success->display#displayResults"
      }
    })
  }

  create(event) {
    let toBeRemoved = event.target.closest('[data-crud-form-container]') || event.target
    toBeRemoved.remove()

    let item = event.detail[2].response
    if (this.onlyOneValue)
      this.itemListTarget.querySelectorAll(`[data-${this.modelNameValue}-id]`).forEach(item => item.remove())

    let itemList
    if (this.topLevelFormValue) {
      itemList = document.querySelector("[data-crud-top-level-item-list]")
    } else {
      itemList = this.itemListTarget
    }

    itemList.insertAdjacentHTML(this.insertPositionValue, item)
  }

  edit(event) {
    let item = event.target.closest('[data-crud-target=item]')
    let html = event.detail[2].response
    if (event.detail[2].responseURL.includes("edit"))
      this.formAction = "edit"

    this.itemListTarget.querySelectorAll("[data-display-only-one]").forEach(onlyOne => onlyOne.remove())

    // setup colspan if needed
    if (event.target.closest("tr")) {
      this.element.querySelectorAll("[data-crud-wrapper]").forEach(form => form.remove())
      let container = event.target.closest('[data-crud-target=item]')
      let colspan = container.querySelectorAll("td").length
      html = `<tr data-crud-wrapper="true"><td colspan="${colspan}">${html}</td></tr>`
    }

    let position = event.params.position || "afterend"
    item.insertAdjacentHTML(position, html)
  }

  update(event) {
    let html = event.detail[2].response

    // remove form itself or wrapper?
    let formContainer
    let wrapper = event.target.closest("[data-crud-wrapper]")
    if (wrapper) {
      formContainer = wrapper
    } else {
      formContainer = event.target
    }

    let item
    if (event.params.replace) {
      item = this.element.querySelector(`[data-${this.modelNameValue}-id="${event.params.id}"]`)
    } else {
      item = formContainer.previousElementSibling
    }

    formContainer.remove()

    // replace with new item
    item.insertAdjacentHTML("beforebegin", html)
    item.remove()
  }

  remove(event) {
    this.itemListTarget.querySelector(`[data-${this.modelNameValue}-id="${event.params.id}"]`).remove()
  }

  error(event) {
    let xhr = event.detail[2]
    let frm = event.target

    // remove any previous messages
    if (frm.querySelector('.alert'))
      frm.querySelector('.alert').remove()

    let json = JSON.parse(xhr.response)
    for (let fld in json) {
      if (this.skipErrorNotificationFieldsValue.includes(fld)) { continue }

      let msg = `${fld} ${json[fld]}`
      msg = msg[0].toUpperCase() + msg.substring(1)
      let alertText = `<div class="alert alert-warning">${msg}</div>`
      frm.insertAdjacentHTML("afterbegin", alertText)
    }
  }
}
