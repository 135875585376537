import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["field", "responseContainer"]
  static outlets = ["spinner"]
  static values = {
    url: String
  }

  async submit(event) {
    event.preventDefault()

    let qry = {}
    this.fieldTargets.forEach(field => { qry[field.name] = field.value })
    const request = new FetchRequest("get", this.urlValue, { query: qry })
    const response = await request.perform()
    if (response.ok) {
      this.responseContainerTarget.querySelectorAll(".submit-wrapper").forEach(wrapper => wrapper.remove())
      let body = await response.html
      body = `<span class="submit-wrapper">${body}</span`
      this.responseContainerTarget.insertAdjacentHTML("beforeend", body)
      if (this.hasSpinnerOutlet) {
        this.spinnerOutlet.stopSpin()
      }
    }

  }
}
