let reconcileMotorProtectionSwitch = function() {
  let fiftyHertz = document.querySelector('[data-emo2-fifty="true"]');
  let noVec = document.querySelector('[data-emo2-vec="false"]');
  let mps = document.querySelector('[data-emo2-mps="true"]');
  let cleaningNotRequired = document.querySelector('[data-cleaning-device] [data-requested="false"]');
  let monitoringNotRequired = document.querySelector('[data-emo-two-monitoring] [data-not-required="true"]');
  if (noVec && cleaningNotRequired.checked && monitoringNotRequired.checked && fiftyHertz) {
    mps.disabled = false;
  } else if (mps) {
    mps.disabled = true;
    mps.checked = false;

    let terminalBox = document.querySelector('[data-emo-two-wiring] [data-terminal-box="true"]');
    if (document.querySelectorAll('[data-emo-two-wiring] input:checked').length < 1) {
      terminalBox.checked = true;
    }
  }
}

let reconcileDirectStarter = function() {
  let starter = document.querySelector('[data-emo-two-wiring] [data-starter="true"]');
  let cleaningNotRequired = document.querySelector('[data-cleaning-device] [data-requested="false"]');
  let fiftyHertz = document.querySelector('[data-emo2-fifty="true"]');
  let d3 = document.querySelector('[data-emo-two-monitoring] [data-d3="true"]');

  if (cleaningNotRequired.checked && fiftyHertz && !d3.checked) {
    starter.disabled = false;
  } else {
    starter.disabled = true;
    starter.checked = false;
  }
}

let reconcileThrottle = function() {
  let throttle = document.querySelector('[data-emo-two-throttle] input[type=checkbox]');
  let cleaningNotRequired = document.querySelector('[data-cleaning-device] [data-requested="false"]');
  let noVec = document.querySelector('[data-emo2-vec=false]');
  let vec = document.querySelector('[data-emo2-vec=true]');

  if (vec) {
    throttle.disabled = true;
    throttle.checked = false;
    return true;
  }

  if (cleaningNotRequired.checked) {
    throttle.disabled = false;
  } else { // cleaning device selected
    throttle.disabled = true;
    throttle.checked = true;
  }
}

let reconcileElectrics = function() {
  let cleaningBackChecked = document.querySelector('[data-back-input="true"]:checked')
  if (cleaningBackChecked) {
    document.querySelector('[data-electric-side]').classList.remove('collapse')
  } else {
    document.querySelector('[data-electric-side]').classList.add('collapse')
  }
}

document.addEventListener('turbo:load', function(){
  // check throttle availability
  let throttle = document.querySelector('[data-emo-two-throttle] input[type=checkbox]');
  if (throttle) {
    throttle.addEventListener('change', () => {
      let cleaningNotRequired = document.querySelector('[data-cleaning-device] [data-requested="false"]');
      if (!cleaningNotRequired.checked) { // if cleaning device, throttle not optional
        throttle.checked = true;
      }
      reconcileThrottle();
    })
  }

  let cleanings = 'body.emo_two_options [data-cleaning-device] input[type="radio"]';
  // toggle inlet/throttle based on cleaning device
  document.querySelectorAll(cleanings).forEach((el) => {
    el.addEventListener('change', (event) => {
      let msg = document.querySelector('[data-cleaning-message]');
      let cleaningDependants = document.querySelectorAll('[data-cleaning-controllable]');
      let deviceRequested = event.target.dataset.requested == 'true'
      cleaningDependants.forEach((el) => {
        el.classList.toggle('collapse', deviceRequested);
        msg.classList.toggle('collapse', !deviceRequested);
      });
      reconcileMotorProtectionSwitch();
      reconcileDirectStarter();
      reconcileThrottle();
      reconcileElectrics();
    });
  });
  let checkedCleaning = document.querySelector(cleanings + ':checked');
  if (checkedCleaning)
    checkedCleaning.dispatchEvent(new Event('change'));

  // toggle Back position based on model chosen
  let backNotPossible = document.querySelector('body.emo_two_options [data-back-possible=false]');
  let cleaningBackContainers = document.querySelectorAll('[data-back="true"]');
  if (backNotPossible && cleaningBackContainers.length > 0) {
    cleaningBackContainers.forEach((el) => el.classList.add('collapse'));
    let backChecks = document.querySelectorAll('[data-back-input="true"]')
    if (document.querySelectorAll('[data-back-input]:checked').length) {
      backChecks.forEach((el) => el.checked = false);
    }
  }

  // check direct starter availability
  let starter = document.querySelector('[data-emo-two-wiring] [data-starter="true"]');
  if (starter) {
    reconcileDirectStarter();
  }

  // check motor protection switch availability when changing monitoring
  let monitorings = document.querySelectorAll('[data-emo-two-monitoring] input[type=radio]');
  if (monitorings) {
    monitorings.forEach((el) => {
      el.addEventListener('change', function() {
        reconcileMotorProtectionSwitch();
        reconcileDirectStarter();
      })
    })
  }

  // toggle monitoring/regulators available based on VEC
  let vec = document.querySelector('[data-emo2-vec="true"]');
  let regulators = document.querySelectorAll('[data-regulator="true"]');
  let monitors = document.querySelectorAll('[data-monitor="true"]');
  let monitoringNotRequired = document.querySelector('[data-emo-two-monitoring] [data-not-required="true"]');
  if (vec) {
    regulators.forEach((el) => el.disabled = false);
    monitors.forEach((el) => {
      el.disabled = true;
      el.checked = false;
    })
    monitoringNotRequired.checked = false;
    monitoringNotRequired.disabled = true;
  } else if(document.querySelector('[data-emo2-vec="false"]')) {
    monitors.forEach((el) => el.disabled = false);
    regulators.forEach((el) => {
      el.disabled = true;
      el.checked = false;
    })
  }
  reconcileMotorProtectionSwitch();
});
