import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="logger"
export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    // console.log("logger connect")
  }

  log(event) {
    console.log("logger#log")
    // console.log(event)
    // console.log(event.target)
    // console.log(event.target.attributes)
  }
}
