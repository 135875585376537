import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["netToApc", "exchangeRate"];

  preferenceValidate() {
    // add leading zero
    if (this.netToApcTarget.value.charAt(0) == ".")
      this.netToApcTarget.value = `0${this.netToApcTarget.value}`

    if (this.exchangeRateTarget.value.charAt(0) == ".")
      this.exchangeRateTarget.value = `0${this.exchangeRateTarget.value}`
  }

  reconcileRegion(event) {
    if (!event.target.checked)
      return

    if (event.target.dataset.checkAmericas == "true")
      document.querySelectorAll("input[data-americas=true][data-released=true]").forEach(cb => cb.checked = true)

    if (event.target.dataset.checkEurope == "true")
      document.querySelectorAll("input[data-europe=true][data-released=true]").forEach(cb => cb.checked = true)

    if (event.target.dataset.checkAsia == "true")
      document.querySelectorAll("input[data-asia=true][data-released=true]").forEach(cb => cb.checked = true)
  }

  checkValid() {
    let result = true
    let groupFieldset = document.querySelector(".group-membership")
    if (groupFieldset.querySelectorAll("input[type=checkbox]:checked").length < 1) {
      this.dispatch("scroll", { detail: { element: groupFieldset } })
      groupFieldset.querySelectorAll(".invalid-feedback").forEach(feedback => feedback.classList.add("d-block"))
      result = false
    }
    return result
  }

  verifyEmailAvailable(event) {
    clearTimeout(this.typingTimeout)
    this.typingTimeout = setTimeout(() => {
      this.checkEmail(event)
    }, 800)
  }

  checkEmail(event) {
    let emailAddress = event.target
    if (emailAddress.value.trim == "")
      return

    let existingEmailAddresses = Array.from(document.querySelectorAll("span.email-address")).map(el => el.innerText)
    if (existingEmailAddresses.includes(emailAddress.value)) {
      let warning = '<div class="email-warning alert alert-warning p-1 mt-2">User already exists</div>'
      event.target.insertAdjacentHTML("afterend", warning)
    } else {
      document.querySelectorAll(".email-warning").forEach(warning => warning.remove())
    }
  }
}
