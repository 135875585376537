import { Controller } from "@hotwired/stimulus"

export default class GsHvsController extends Controller {
  static targets = ["models", "regularMedia", "smallMedia"]
  static values = {
    europe: Boolean
  }

  connect() {
    this.modelChange()
  }

  modelChange(_event) {
    let mediaListToShow
    let smallModelChosen = this.selectedModel().innerText.includes("M")

    if (smallModelChosen) {
      this.regularMediaTarget.classList.add("d-none")
      this.smallMediaTarget.classList.remove("d-none")
      mediaListToShow = this.smallMediaTarget
    } else {
      this.regularMediaTarget.classList.remove("d-none")
      this.smallMediaTarget.classList.add("d-none")
      mediaListToShow = this.regularMediaTarget
    }

    let checkedCartridgeInvisible = document.querySelector("input[id*=gs_hv_cartridge_]:checked").offsetWidth < 1
    if (checkedCartridgeInvisible)
      mediaListToShow.querySelector("input[id*=gs_hv_cartridge_]").checked = true
  }

  selectedModel() {
    return this.modelsTarget.querySelector("option:checked")
  }

  smallMediaChosen() {
    return this.smallMediaTarget.querySelectorAll("input:checked").length > 0
  }

  regularMediaChosen() {
    return !this.smallMediaChosen()
  }
}
