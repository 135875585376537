document.addEventListener("turbo:load", () => document.addEventListener("scroll", darkenMenu))

function darkenMenu() {
  let header = document.querySelector(".header")
  let headerHeight = header.offsetHeight
  let wideHeader = header.offsetWidth > 992
  let menu = document.querySelector(".container.menus")
  let elementsToDarken = document.querySelectorAll("header.navbar, .project--menu, .collector--menu")

  if (window.scrollY > headerHeight) {
    elementsToDarken.forEach(el => el.classList.add("darken"))

    if (wideHeader) { // shorten menu
      header.querySelector("img").style.height = "48px"
      document.querySelector(".navbar-supplemental").classList.add("collapse")
      if (menu)
        menu.style.top = "48px" // QOW fills empty space
    }
  } else {
    elementsToDarken.forEach(el => el.classList.remove("darken"))

    if (wideHeader) { // make menu big
      header.querySelector("img").style.height = "98px"
      document.querySelector(".navbar-supplemental").classList.remove("collapse")
      if (menu)
        menu.style.top = `${header.offsetHeight}px` // QOW fills empty space
    }
  }
}
