import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["none", "horsepower", "sixty", "horsepowerSixty", "horsepowerFifty",
                    "vfdExplanation", "vfdBlowerControls"]

  connect() {
    this.controlChange()
  }

  controlChange() {
    if (this.noneTarget.checked) {
      this.horsepowerTargets.forEach((checkbox) => {
        checkbox.checked = false
        checkbox.disabled = true
      })
      return
    }

    if (this.sixtyChosen()) {
      this.horsepowerSixtyTargets.forEach((hpSixty) => hpSixty.disabled = false)
      this.horsepowerFiftyTargets.forEach((hpFifty) => {
        hpFifty.checked = false
        hpFifty.disabled = true
      })

      // not all horsepowers can use 208V control
      if (this.chosenControl().dataset.ic208 == "true") {
        this.horsepowerSixtyTargets.forEach((hp) => {
          if (hp.dataset.ic208 == "false") {
            hp.checked = false
            hp.disabled = true
          }
        })
      }

    } else { // fifty chosen...
      this.horsepowerFiftyTargets.forEach((hpFifty) => hpFifty.disabled = false)
      this.horsepowerSixtyTargets.forEach((hpSixty) => {
        hpSixty.checked = false
        hpSixty.disabled = true
      })
    }

    this.reconcileBlowerControls()
  }

  sixtyChosen() {
    let anySixties = false
    this.sixtyTargets.forEach((checkbox) => {
      if (checkbox.checked)
        anySixties = true
    })
    return anySixties
  }

  chosenControl() {
    if (this.noneTarget.checked) return

    return document.querySelector("input[name*=integrated_control]:checked")
  }

  reconcileBlowerControls() {
    if (this.chosenControl().dataset.vfd == "true") {
      this.vfdExplanationTarget.classList.add("collapse")
      this.vfdBlowerControlsTarget.classList.remove("collapse")
      return
    }

    if (this.chosenControl().dataset.vfd == "false") {
      this.vfdExplanationTarget.classList.remove("collapse")
      this.vfdBlowerControlsTarget.querySelectorAll("input[type=checkbox]").forEach((checkbox) => checkbox.checked = false)
      this.vfdBlowerControlsTarget.classList.add("collapse")
    }
  }
}
