import { Controller } from "@hotwired/stimulus"

export default class AttributeController extends Controller {
  static targets = ["setter", "valueSetter"]

  setterTargetConnected(target) {
    if (target.dataset.action == undefined) {
      target.dataset.action = "attribute#reconcile"
    } else {
      target.dataset.action += " attribute#reconcile"
    }
  }

  valueSetterTargetConnected(target) {
    if (target.dataset.action == undefined) {
      target.dataset.action = "attribute#reconcileValue"
    } else {
      target.dataset.action += " attribute#reconcileValue"
    }
  }

  reconcile(event) {
    document.querySelectorAll(event.params.nodes).forEach(element => {
      if (typeof(event.params.value) == "boolean") {
        if (event.params.value) {
          console.log(`should be setting ${element.innerText} ${event.params.property} to true`)
          element.setAttribute(event.params.property, event.params.value)
        } else {
          element.removeAttribute(event.params.property)
        }

        element.dispatchEvent(new Event("change"))
        return
      }

      element.setAttribute(event.params.property, event.params.value)
    })
  }

  reconcileValue(event) {
    document.querySelectorAll(event.params.nodes).forEach(element => {
      element.value = event.params.value
      element.dispatchEvent(new Event("change"))
    })
  }
}
