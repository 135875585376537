import { Controller } from "@hotwired/stimulus"

export default class SpinnerController extends Controller {
  static targets = ["spinnerImage", "clicker"];
  static values = {
    auto: { type: Boolean, default: true },
    checkBox: { type: Boolean, default: false },
    positionAfterClicker: { type: Boolean, default: false },
    positionBeforeClicker: { type: Boolean, default: false },
    positionEndClicker: { type: Boolean, default: false },
    timesOut: { type: Boolean, default: false },
    debug: { type: Boolean, default: false }
  }

  clickerTargetConnected(target) {
    if (target.hasAttribute("data-action"))  {
      target.dataset.action += " spinner#spin ajax:success->spinner#stopSpin"
    } else {
      target.dataset.action = "spinner#spin ajax:success->spinner#stopSpin"
    }
  }

  connect() {
    if (!this.autoValue)
      return

    let html = "<i class='d-none mx-2 fa-solid fa-spinner fa-spin' data-spinner-target='spinnerImage'></i>"
    if (this.positionAfterClickerValue) {
      if (this.hasClickerTarget)
        this.clickerTarget.insertAdjacentHTML("afterEnd", html)
    } else if (this.positionBeforeClickerValue) {
      if (this.hasClickerTarget)
        this.clickerTarget.insertAdjacentHTML("beforeBegin", html)
    } else if (this.positionEndClickerValue) {
      if (this.hasClickerTarget)
        this.clickerTarget.insertAdjacentHTML("beforeEnd", html)
    } else {
      this.element.insertAdjacentHTML("beforeend", html)
    }
  }

  spin(event) {
    if (this.checkBoxValue && !event.target.checked)
      return
    this.spinnerImageTarget.classList.remove("d-none")
    if (this.timesOutValue)
      setTimeout(() => this.stopSpin(), 1000)
  }

  stopSpin() {
    this.spinnerImageTarget.classList.add("d-none")
  }
}
