import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'noSingle', 'noParallel', 'noSeries',
                     'yesSingle', 'yesParallel', 'yesSeries' ];

  connect() {
    this.modelChange();
  }

  modelChange() {
    if (document.querySelector('[data-single="true"]').checked) {
      this.noSingleTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.yesSingleTargets.forEach((element) => element.disabled = false);
    }

    if (document.querySelector('[data-parallel="true"]').checked) {
      this.noParallelTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.yesParallelTargets.forEach((element) => element.disabled = false);
    }

    if (document.querySelector('[data-series="true"]').checked) {
      this.noSeriesTargets.forEach((element) => {
        element.disabled = true;
        element.checked = false;
      });
      this.yesSeriesTargets.forEach((element) => element.disabled = false);
    }
  }
}
