document.addEventListener('turbo:load', function(){
  let usages = '[data-ec-usage] input[type=radio]';
  document.querySelectorAll(usages).forEach((el) => {
    el.addEventListener('change', (event) => {
      let cleaningDeviceContainer = document.querySelector('[data-ec-cleaning-devices]');
      if (el.dataset.emulsion == 'true') {
        cleaningDeviceContainer.classList.remove('collapse')
      } else {
        cleaningDeviceContainer.classList.add('collapse')
      }
    })
  })
  let checkedUsage = document.querySelector(usages + ':checked');
  if (checkedUsage)
    checkedUsage.dispatchEvent(new Event('change'));
})
