import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['catalogHolder', 'selectedDocs'];

  checkDocsToConnect(event) {
    if (!this.catalogHolderTarget.classList.contains('collapse')) {
      this.catalogHolderTarget.classList.add('collapse');
      event.preventDefault();
      return false;
    }
  }

  showDocsToConnect(event) {
    this.catalogHolderTarget.innerHTML = event.detail[2].response;
    this.catalogHolderTarget.classList.remove('collapse')
  }

  checkCatalog(event) {
    const checkSelector = `div[data-catalog-id="${event.target.dataset.catalogId}"]`
    const catalog = document.querySelector(checkSelector);
    if (catalog) {
      event.preventDefault();
      catalog.remove();
      return false;
    }
  }

  showCatalog(event) {
    const parentCol = event.target.closest('.col-6');
    const xhr = event.detail[2].response;
    parentCol.insertAdjacentHTML('beforeend', xhr);
  }

  checkDocs(event) {
    const checkSelector = `div[data-category-id="${event.target.dataset.categoryId}"]`;
    const categoryDocs = document.querySelector(checkSelector);
    if (categoryDocs) {
      event.preventDefault();
      categoryDocs.remove();
      return false;
    }
  }

  showDocs(event) {
    const parentCol = event.target.closest('.row');
    const xhr = event.detail[2].response;
    parentCol.insertAdjacentHTML('beforeend', xhr)
  }

  docProcess(event) {
    const docId = event.target.dataset.docId;
    const alreadySelectedDoc =
      this.selectedDocsTarget.querySelector(`[data-doc-id="${docId}"]`);

    // remove doc if already selected
    if (alreadySelectedDoc) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.removeDoc(docId);
      return false;
    }

    this.docAdd(docId)
  }

  docAdd(docId) {
    const docTitle = event.target.dataset.docTitle;

    // add to UI
    const docIndicator =
      `<span data-doc-id="${docId}"></span>`;
    const docLink =
      `<a class="btn-times" data-doc-id="${docId}" data-action="doc-option#docProcess">${docTitle}</a>`;
    this.selectedDocsTarget.insertAdjacentHTML('beforeend', docIndicator);
    this.selectedDocsTarget.lastChild.insertAdjacentHTML('beforeend', docLink); // lastChild is the docIndicator span
    this.selectedDocsTarget.lastChild.classList.add('badge', 'badge-secondary');
    event.target.classList.add('btn-check');

    // add to dataset
    let newDocIds = this.selectedDocsTarget.dataset.selectedDocIds.split(',');
    newDocIds.push(docId);
    newDocIds = newDocIds.filter(id => id) // remove blank entries (if first doc added)
    this.selectedDocsTarget.dataset.selectedDocIds = newDocIds;

    this.saveToServer();
  }

  removeDoc(docId) {
    // remove doc from UI
    let selectedDoc = this.selectedDocsTarget.querySelector(`span[data-doc-id="${docId}"]`);
    selectedDoc.remove();
    this.catalogHolderTarget.querySelectorAll(`[data-doc-id="${docId}"]`).forEach((link) => link.classList.remove('btn-check'))

    // remove doc from dataset
    let docIds = this.selectedDocsTarget.dataset.selectedDocIds.split(',');
    docIds = docIds.filter((id) => id !== docId);
    this.selectedDocsTarget.dataset.selectedDocIds = docIds;

    this.saveToServer();
  }

  saveToServer() {
    Rails.ajax({
      type: "post",
      url: this.selectedDocsTarget.dataset.url,
      data: `docs=${this.selectedDocsTarget.dataset.selectedDocIds}`,
      success: () => {
        document.querySelector('[data-save-confirmation]').classList.remove('collapse');
        setTimeout(() => {
          document.querySelector('[data-save-confirmation]').classList.add('collapse');
        }, 1500);
      }
    });
  }
}
