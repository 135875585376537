import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["existingDocs"]
  addDoc(event) {
    if (event.target.checked) {
      let html = event.detail[2].response
      this.existingDocsTarget.insertAdjacentHTML("beforeend", html)
      return
    }

    this.removeDoc(event)
  }

  removeDoc(event) {
    let selector = `[data-project-docs-id="${event.params.id}"]`
    let existingDoc = this.existingDocsTarget.querySelector(selector)
    existingDoc.remove()
  }
}
