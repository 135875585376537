document.addEventListener('turbo:load', function() {
  const paintTypes = document.querySelectorAll('[data-paint-check=true]');
  if (!paintTypes.length)
    return true

  paintTypes.forEach((paintType) => {
    paintType.addEventListener('change', (event) => {
      const rdio = event.target;
      const container = rdio.closest('[data-paint-holder]');
      document.querySelectorAll('[data-paint-controlled]').forEach((el) => {
        el.disabled = true;
      })
      container.querySelectorAll('[data-paint-controlled]').forEach((el) => {
        el.disabled = false;
      })
    })
  });
  document.querySelector('[data-paint-check]:checked').dispatchEvent(new Event('change'));

  // plenum controls hood and checks duct, but allows unchecking duct
  const plenum = document.querySelector('[data-hv-plenum="true"]');
  const cntrls = document.querySelectorAll('[data-hv-plenum-controlled="true"]');
  const hood = document.querySelector('[data-hv-hood="true"]');
  const duct = document.querySelector('[data-hv-duct="true"]');
  if (!plenum)
    return true

  plenum.addEventListener('change', (event) => {
    if (event.target.checked) {
      cntrls.forEach((cntrl) => {
        cntrl.disabled = false;
        cntrl.checked = true;
      })
    } else {
      hood.disabled = true;
      hood.checked = false;
      duct.checked = false;
    }
  });

  // allow to keep unchecked hood on page load
  if (plenum.checked) {
    hood.disabled = false;
  } else {
    hood.disabled = true;
  }
});
