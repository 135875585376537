import { Controller } from "@hotwired/stimulus"

export default class LinkToggleController extends Controller {
  static targets = ["toggler", "togglee"]
  static values = {
    nodes: String
  }

  initialize() {
    this.toggleeTargets.forEach((togglee) => {
      togglee.classList.add('animate__animated')
      togglee.addEventListener('animationend', () => {
        if (togglee.classList.contains('animate__fadeOut')) {
          togglee.classList.add('d-none')
        } else { // showing the element
          togglee.classList.remove('d-none')
        }
      })
    })

    if (this.hasNodesValue) {
      let nodes = document.querySelectorAll(this.nodesValue)
      nodes.forEach((node) => {
        node.classList.add('animate__animated')
        node.addEventListener('animationend', () => {
          if (node.classList.contains('animate__fadeOut')) {
            node.classList.add('d-none')
          } else { // showing the element
            node.classList.remove('d-none')
          }
        })
      })
    }
  }

  toggle(event) {
    event.preventDefault()
    let needToShow = this.toggleeTarget.classList.contains("d-none")

    this.toggleeTargets.forEach((togglee) => {
      if (needToShow) {
        togglee.classList.remove('animate__fadeOut', 'd-none')
        togglee.classList.add('animate__fadeIn')
      } else {
        togglee.classList.add('animate__fadeOut')
      }
      return
    })
  }

  toggleNodes(event) {
    event.preventDefault()

    let togglees = document.querySelectorAll(this.nodesValue)
    togglees.forEach((togglee) => {
      let needToShow = togglee.classList.contains("d-none")

      if (needToShow) {
        togglee.classList.remove('animate__fadeOut', 'd-none')
        togglee.classList.add('animate__fadeIn')
      } else {
        togglee.classList.add('animate__fadeOut')
      }
      return
    })
  }

  show(element) {
    element.classList.remove("animate__fadeOut", "d-none")
    element.classList.add("animate__fadeIn")
  }

  hide(element) {
    element.classList.add("animate__fadeOut")
  }
}
