
import { Controller } from "@hotwired/stimulus"

export default class CheckHideController extends Controller {
  static targets = ["hider"]
  static values = {
    nodes: String
  }

  hiderTargetConnected(target) {
    if (this.hasNodesValue) {
      if (target.dataset.action === undefined) {
        target.dataset.action = "check-hide#hideNodes"
      } else {
        target.dataset.action += " check-hide#hideNodes"
      }
    }
  }

  hideNodes() {
    document.querySelectorAll(this.nodesValue).forEach(node => {
      if (this.hiderTarget.checked) {
        node.classList.add("d-none")
      }
    })
  }
}
