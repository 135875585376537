import { Controller } from "@hotwired/stimulus"

export default class CheckEnableController extends Controller {
  static targets = ["enabler"]
  static values = {
    nodes: String,
    skipFireOnLoad: { type: Boolean, default: false },
    andCheck: { type: Boolean, default: false },
    debug: Boolean
  }

  enablerTargetConnected(target) {
    if (target.dataset.action === undefined) {
      target.dataset.action = "check-enable#enableNodes"
    } else {
      target.dataset.action += " check-enable#enableNodes"
    }
  }

  connect() {
    if (this.debugValue) console.log("connect check-enable")

    if (this.skipFireOnLoadValue)
      return

    setTimeout(() => {
      this.enablerTargets.forEach(enabler => {
        enabler.dispatchEvent(new Event("input"))
      })
    }, 333)

  }

  enableNodes(event) {
    if (this.debugValue) console.log("check-enable->enableNodes")

    document.querySelectorAll(this.nodesValue).forEach(node => {
      if (event.target.checked) {
        if (this.debugValue) console.log("disabling")
        node.disabled = false

        if (this.andCheckValue) {
          if (this.debugValue) console.log("checking")
          node.checked = true
        }

      }
    })
  }
}
