import { Controller } from "@hotwired/stimulus"
import { DataTable } from "simple-datatables"

export default class DatatableController extends Controller {
  static values = {
    crud: { type: Boolean, default: false },
    rowControllers: String,
    tbodyAttributes: Object,
    rowAttributes: Object,
    searchable: { type: Boolean, default: true },
    firstColumnSort: { type: Boolean, default: true }
  }

  connect() {
    let tbodyAttributes = {}
    let trAttributes = {}

    if (this.crudValue) {
      tbodyAttributes = { "data-crud-target": "itemList" }
      trAttributes = { "data-controller": `dismiss ${this.rowControllersValue}`, "data-crud-target": "item" }
    }

    if (this.tbodyAttributesValue)
      tbodyAttributes = { ...tbodyAttributes, ...this.tbodyAttributesValue }

    if (this.rowAttributesValue) {
      trAttributes = { ...trAttributes, ...this.rowAttributesValue }
    }

    new DataTable(this.element, {
      paging: false,
      ordering: false,
      info: false,
      searchable: this.searchableValue,
      rowRender: (_data, row, _type) => {
        row.attributes = trAttributes
      },
      tableRender: (_data, table, _type) => {
        table.childNodes[1].attributes = tbodyAttributes
      },
      columns: [
        { select: 0, sortable: this.firstColumnSortValue }
      ]
    })

  }
}
