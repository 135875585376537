import { Controller } from "@hotwired/stimulus"

export default class LinkShowController extends Controller {
  static values = {
    nodes: String,
    nodesByElement: String,
    runOnLoad: Boolean
  }

  connect() {
    if (this.runOnLoadValue) {
      let mainElement = this.element
      if (mainElement.tagName == "SELECT") {
        mainElement.dispatchEvent(new Event("change"))
      }
    }
  }

  showNodes(event) {
    event.preventDefault()
    document.querySelectorAll(this.nodesValue).forEach(element => {
      element.classList.remove("d-none")
    })
  }

  showNodesByElement(event) {
    event.preventDefault()
    let mainElement = document.querySelector(this.nodesByElementValue)
    let nodesToShow = mainElement.dataset.linkShowNodesParam
    document.querySelectorAll(nodesToShow).forEach(node => {
      node.classList.remove("d-none")
    })
  }
}
