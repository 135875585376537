import { Controller } from "@hotwired/stimulus"

export default class LinkMessageController extends Controller {
  static targets = ["messenger"]

  messengerTargetConnected(target) {
    if (!target.hasAttribute("data-link-message-message")) {
      target.dataset.linkMessageMessage = "Success"
    }
    if (target.hasAttribute("data-action")) {
      target.dataset.action += " ajax:success->link-message#message"
    } else {
      target.dataset.action = "ajax:success->link-message#message"
    }
  }

  connect() {
    if (this.element.hasAttribute("data-link-message-message")) {
      let html = `<span class="d-none small p-2 m-2 ms-0 text-success">${this.element.dataset.linkMessageMessage}</span>`
      this.element.insertAdjacentHTML("afterend", html)
    }
  }

  message(event) {
    event.target.nextSibling.classList.remove("d-none")
  }
}
