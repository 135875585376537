import { Controller } from "@hotwired/stimulus"

export default class CountController extends Controller {
  static values = {
    counterSelector: { type: String, default: "[data-count-target='counter']" },
    itemsToCountSelector: String
  }

  connect() {
    this.updateCount()
  }

  updateCount() {
    let count = document.querySelectorAll(this.itemsToCountSelectorValue).length
    console.log(count)
    this.counterElement().innerText = count
  }

  counterElement() {
    return document.querySelector(this.counterSelectorValue)
  }
}
